/**
 * ==================== @ ICONS
 */

.svg-icon {
	//color: $c-greynite;
	display: inline-block;
	fill: currentColor;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
	width: 1em;
}

.icon-rs {
	a {
		//background-color: $c-greynite;
		border-radius: 50%;
		display: block;
		height: 32px;
		width: 32px;
		line-height: 32px;
		text-align: center;
		transition: background-color 250ms;
		
		.svg-icon {
			color: $c-white;
		}
	}
	
	&:last-child a{
		line-height: 31px;
	}
}

.icon-check,
.icon-close {
	border-radius: 50%;
	background-color: $c-white;
	display: inline-block;
	height: 28px;
	line-height: 27px;
	text-align: center;
	width: 28px;	
}

.icon-check .check {
	color: $c-green;
}

.icon-close .close {
	font-size: 12px;
}

@media screen and (min-width: $bp-l) {
	
	.icon-rs {
		a:hover {
			background-color: $c-green;
		}
	}
}