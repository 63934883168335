/**
 * ==================== @ GRID LAYOUT
 */

.grid-wrapper {
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 80em;
	margin: 0 auto;
	
	&.wrapper-10-cols {
		max-width: 65em;
	}
	
	&.wrapper-8-cols {
		max-width: 52em;
	}
}

.grid-layout {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	column-gap: 1rem;
	overflow: hidden;
	padding: 1.5rem;
	
	.grid-item {
		grid-column: 1 / -1;
	}
	
	.grid-item.section-title {
		text-align: center;
	}
		
	.bg-white &, &.bg-white {
		padding: 0 0 .5rem 0;
	}
	
	&.full-width {
		padding: 0;
	}
		
	&.layout-two-cols {
		gap: 1rem;
		padding: 0;
	}
		
	&.layout-2cols {

		.btn-wrapper {
			grid-column: 1 / -1 !important;
		}
	}
		
	&.layout-three-cols {
		gap: 1rem;
		padding: .5rem 2rem;
	}
	
	&.layout-video {
		padding: 1.5rem 0;

    .grid-item {
      display: flex;
      flex-direction: column;
      gap: .75rem;

      .video_title {
        font-size: rem(22);
        font-family: $font-alt;
        font-weight: $fw-semibold;
        padding: 0 1rem;
      }

      .video_caption {
        font-size: rem(16);
        padding: 0 1rem;

        p {
          font-size: rem(16);
          margin: 0;
        }
      }
    }
	}
	
	.m--snap-left &,
	.m--snap-right & {
		padding: 0;
		
		.grid-item {
			padding: 0;
		}
	}
	
	&.layout-img-centered.full-width {	

		.grid-item {
			padding: 2rem 0;
		}
	}
}

@media screen and (max-width:1023px) {
	
	.grid-wrapper {
	
		&.m--snap-right {
			padding-left: 0;
			padding-right: 0;
      
			.list-wrapper {
				grid-row: 2 / auto;
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {
	
	.grid-wrapper {
		
		&.m--boxed {
			max-width: 48em;
			margin: 0 auto;		
		}
		
		&.m--snap-left ,
		&.m--snap-right {
			position: relative;
		}
	}
	
	.grid-layout {
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: repeat(2, auto);
		column-gap: 2rem;
		padding: 3rem 4rem 2rem 4rem;
		
		.bg-white &, &.bg-white {
			padding: 0 0 1rem 0;
		}
		
		&.bloc-head {
			padding: 2rem 0;
			
			.grid-item {
				padding: 0 1rem;
			}
		}
		
		&.layout-txt-img {
			
			.grid-item:first-child {
				grid-column: span 7;
				padding: 1rem;
			}
			
			.grid-item:last-child {
				grid-column: span 5;
			}
			
			&.bloc-head {
			
				.grid-item:first-child {
					grid-column: 1 / span 7;
					grid-row: 1 / auto;
				}
				
				.grid-item:nth-of-type(2)  {
					grid-column: 8 / span 5;
					grid-row: 1 / span 2;
				}
				
				.grid-item:last-child {
					grid-column: 1 / span 7;
					grid-row: 2 / auto;
				}				
			}
		}
		
		&.layout-img-txt {
						
			.grid-item:first-child {
				grid-column: span 5;
			}
			
			.grid-item:last-child {
				grid-column: span 7;
				padding: 1rem;
			}
			
			&.bloc-head {
			
				.grid-item:first-child {
					grid-column: 6 / span 7;
					grid-row: 1 / auto;
				}
				
				.grid-item:nth-of-type(2)  {
					grid-column: 1 / span 5;
					grid-row: 1 / span 2;
				}
				
				.grid-item:last-child {
					grid-column: 6 / span 7;
					grid-row: 2 / auto;
				}
			}
		}
		
		&.layout-txt,
		&.layout-boxed-8,
		&.layout-img-centered {	
	
			.grid-item {
				grid-column: 3 / -3;
				padding: 0;
			}	
		}
		
		&.layout-img-centered.full-width {	
	
			.grid-item {
				padding: 2rem 0;
			}	
		}
		
		&.layout-txt.rounded-60,
		&.layout-txt.rounded-120 {
				
			.grid-item {
				grid-column: 1 / -1;
				padding: 0;
			}			
		}
	
		&.layout-boxed-10 {
	
			.grid-item {
				grid-column: 2 / -2;
			}			
		}
		
		&.layout-txt.full-width {
			padding: 3rem 0;
		}
		
		&.layout-2cols {
			.grid-item:not(.full-width) {
				grid-column: span 6;
			}
		}
		
		&.layout-two-cols {
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem 3rem;
						
			.grid-item {
				grid-column: span 1;
			}
		}
		
		&.layout-three-cols {
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 1.5rem;
			padding: 2rem 0;
						
			.grid-item {
				grid-column: span 1;
			}
						
			.grid-item.m--full {
				grid-column: span 3;
			}
		}

		/* Specific page contact
		------------------------*/
		.m--snap-left & {
			
			.grid-item:nth-of-type(1) {
				grid-column: 1 / span 5;
				position: absolute;
				left: 0;
				width: calc((100vw * 5) / 12);
				transform: translateX(calc((100vw - 1280px)/2 * -1));
			}
			
			.grid-item:nth-of-type(2) {
				grid-column: 6 / span 7;
			}
			
		}
		
		.m--snap-right & {
			
			.list-wrapper {
				grid-column: 1 / span 6;
			}
			
			.map-wrapper {
				grid-column: 7 / span 6;
				
				.js-map-view {
					position: relative;
				}
			}
		}
	
		.grid-item.section-title {
			grid-column: 1 / -1;
			padding: 0;
			text-align: center;
			
			h2 {
				margin: 0;
			}
		}
		
		.grid-item.vcenter {
			align-self: center;
		}
	}
}

@media screen and (min-width:$bp-1280) {

	.grid-layout {
    
		.m--snap-right & {
      
			.map-wrapper {
				position: absolute;
				right: 0;
				transform: translateX(calc((100vw - 1280px)/2));
				width: 50vw;
        
				.js-map-view {
					min-width: 100%;
					position: absolute;	
				}
			}
	
      &.layout-video {
        padding: 3rem 4rem 2rem 4rem;
  
        .grid-item {
          display: flex;
          flex-direction: column;
          gap: .75rem;
  
          .video_title {
            font-size: rem(20);
            padding: 0 1rem;
          }
  
          .video_caption {
            font-size: rem(16);
            padding: 0 1rem;
  
            p {
              font-size: rem(16);
              margin: 0;
            }
          }
        }
      }
		}
	}
}