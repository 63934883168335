/*
 * ==================== @ SLICK THEME
 */

.slider {
	opacity: 0;
  transition:opacity 400ms ease-out;
	
	&.slick-initialized { 
		opacity: 1;
	}
	
	.slider__slide {
		border-radius: $radius-24;
		margin: 1rem;
		position: relative;
		overflow: hidden;
		
		img {
			display: block;
		}
	}
	
	.slick-arrow {
		position: absolute;
		height: 48px;
		width: 48px;
		border: 3px solid $c-white;
		border-radius: 12px;
		font-size: 0;
		position: absolute;
		left: 50%;
		bottom: 0;
		line-height: 48px;
		z-index: 4;
		
		&::before {
			content: '';
			color: #fff;
			font-size: 12px;
			line-height: 36px;
			position: absolute;
			top: 38%;
			left: 38%;
		}
		
		&.slick-prev {
			transform: translate(-56px,4rem);
			&::before {			
				@include background-svg($svg-arrow-left, $c-white);
			}
		}
		
		&.slick-next {		
			transform: translate(16px,4rem);
			&::before {
				@include background-svg($svg-arrow, $c-white);
			}
		}
		
		&.slick-disabled {
			opacity: .5;
		}
	}
}


@media only screen and (max-width: 639px) {
}

@media only screen and (min-width: $bp-l) {
}