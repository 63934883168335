/**
 * ==================== @ FOOTER
 */

.footer {
}

.footer-inner {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	padding: 2rem 0;
	
	.website-logo-footer {
		img {
			max-height: 60px;
			margin-bottom: .5rem;
			width: auto;
		}
	}
}

.footer-inner__item {
	flex: 1 0 100%;
	margin-bottom: 1.5rem;
	
	a {
		color: $c-white;
	}
		
	&.footer-rs {
		
		a {
			margin-right: 1.2rem;
			color: $c-white;
			
			&:first-child {
				vertical-align: -1px;
			}
		}
		
		.svg-icon {
			font-size: em(20);
		}
	}
	
	&:last-child {
		color: rgba($c-white, .4);
		margin-bottom: 0;
	}
}

@media screen and (min-width: $bp-l) {
	
	.footer-inner {
		display: flex;
		align-content: center;
		align-items: center;
		padding: 2rem 0;
	
		.website-logo-footer {
			img {
				max-height: 72px;
				margin-bottom: 0;
			}
		}
	}
	
	.footer-inner__item {
		flex: 1 0 auto;
		margin-bottom: 0;
		
		&.footer-rs {
			flex: 1 0 auto;
			text-align: right;
			
			a {
				color: rgba($c-white, .6);
				transition: color 250ms;
				
				&:hover {
					color: $c-white;
				}
			}
		}
		
		&:last-child {
			flex: 1 0 auto;
			//align-self: flex-end;
			text-align: right;
			padding-top: 4px;
		}
	}
}


@media screen and (min-width: 1280px) {
	
}