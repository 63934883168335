/**
 * ==================== @ GENERIC FLOW TOOLS
 */

.l-clearfix { @extend %clearfix; }

.l-wrapper {
	letter-spacing: .1px;
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 80em;
	margin: 0 auto;
  position: relative;
	z-index: 1;
}

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
	text-align: center;
}

.l-right {
	text-align: right;
}

.padding-t-2 {
	padding-top: 2rem;
}

.padding-t-3 {
	padding-top: 3rem;	
}

.padding-t-4 {
	padding-top: 4rem;	
}

/* EMBED
----------------------------------*/
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
	min-height: 600px;
	overflow: hidden;
}

#contact-form {
	scroll-margin-top: 150px;	
}

.l-scrollbar {
  position: relative;
}

/* Backgrounds
------------------------*/
.bg-grey {
	background-color: $c-grey;
	color: $c-green-txt;
	p, h2, h3, h4 {
		color: $c-green-txt;
	}
}

.bg-greyblue {
	background-color: $c-greyblue;
	color: $c-green-txt;
	p, h2, h3, h4 {
		color: $c-green-txt;
	}
}

.bg-rose {
	background-color: $c-rose;
	color: $c-violet;
	p, h2, h3, h4 {
		color: $c-violet;
	}
}

.bg-violet {
	background-color: $c-violet;
	color: $c-white;
	p, h2, h3, h4 {
		color: $c-white;
	}
}

.c-green {
	color: $c-green;
}

.c-violet {
	color: $c-violet;
}
	
/* radius layout
------------------------*/
.rounded-24 {
	border-radius: $radius-24;
}

.rounded-60:not(.bg-white) {
	border-radius: $radius-48;
}

.rounded-120:not(.bg-white) {
	border-radius: $radius-48;
}
	
.half-rounded {
	border-radius: 0 $radius-48 0 $radius-48;
}
	
.half-rounded-alt {
	border-radius: $radius-48 0 $radius-48 0;
}
	
/* specific img centered
------------------------*/
.layout-img-centered {
	border-radius: 0;
	
	figure {
		border-radius: 0;
	}
		
	&.rounded-60 figure {
		border-radius: $radius-48;
	}
		
	&.rounded-120 figure {
		border-radius: $radius-48;
	}
		
	&.half-rounded figure {
		border-radius: 0 $radius-48 0 $radius-48;
	}
}

@media screen and (max-width: 767px) {

	.l--desktop {
		display: none;
	}  
}


@media screen and (min-width: $bp-l) {

	.website-logo {	
		height: 94px;
	}

  .l--mobile {
    display: none;
  }
	
	/* radius layout
	------------------------*/
	.rounded-60:not(.bg-white) {
		border-radius: $radius-60;
	}
	
	.rounded-120:not(.bg-white) {
		border-radius: $radius-120;
	}
		
	.half-rounded {
		border-radius: 0 $radius-120 0 $radius-120;
	}
	
	.half-rounded-alt {
		border-radius: $radius-120 0 $radius-120 0;
	}
	
	/* specific img centered
	------------------------*/	
	.layout-img-centered {
		border-radius: 0;
		
		figure {
			border-radius: 0;
		}
			
		&.rounded-60 figure {
			border-radius: $radius-60;
		}
			
		&.rounded-120 figure {
			border-radius: $radius-120;
		}
			
		&.half-rounded figure {
			border-radius: 0 $radius-120 0 $radius-120;
		}
	}
}