/**
 * ==================== @ BREADCRUMB
*/

.breadcrumb__wrapper {
}

.breadcrumb {
	padding: .2rem 0;
}

.breadcrumb__list {
	list-style: none;
	padding: 0;

	li {
		display: inline-block;
		font-size: em(16);
		margin-right: .75rem;
		
		.home {
			color: $c-white;
			font-size: em(24);
			vertical-align: -6px;
		}
		
		.arrow-right {
			color: $c-white;
			font-size: em(9);
			margin-right: .3rem;
			vertical-align: middle;
		}
		
		span, a {
			color: $c-white;
		}
	}
}

@media screen and (max-width:639px) {
}

@media screen and (min-width:$bp-m) {
}