/**
 * ==================== @ BUTTONS
 */

.btn-wrapper {
	padding: 1rem 0;

	&.m--center {
		text-align: center;
	}
	
	&.m--right {
		text-align: right;
	}
}

.btn {
	background-color: transparent;
	border: 4px solid $c-green;
	border-radius: 6px;
	color: $c-green;
	display: inline-block;
	font-size: em(18);
	font-weight: $fw-bold;
	padding: .75rem 1rem;
	text-transform: uppercase;
	transition: background-color 250ms, color 250ms;
	text-align: center;
	
	.bg-grey & {
		background-color: $c-white;
		border: 4px solid $c-green;
		color: $c-green;
	}
	
	.bg-greyblue & {
		background-color: $c-white;
		border: 4px solid $c-green-txt;
		color: $c-green-txt;
	}
	
	.bg-rose & {
		background-color: $c-white;
		border: 4px solid $c-violet;
		color: $c-violet;
	}
	
	.bg-violet & {
		border: 4px solid $c-white;
		color: $c-white;
	}
	
	&.m--violet {		
		background-color: $c-white;
		border: 4px solid $c-violet;
		color: $c-violet;
	}
	
	&.m--contact {
		border: 0;
		border-radius: 999px;
		background-color: $c-white;
		color: $c-violet;
		font-size: em(16);
		line-height: 1;
		padding: .7rem 1.5rem .6rem 1.5rem;
	}
	
	&.m--white {
		background-color: transparent;
		border: 4px solid $c-white;
		color: $c-white;
	}
	
	&.m--smaller {
		font-size: em(14);
	}
}

@media all and (min-width : $bp-l) {
	
	.btn {
		padding: 1rem 2rem;
		text-align: left;
	
		.bg-grey & {
	
			&:hover, &.active {
				background-color: $c-green;
				color: $c-white;
			}
		}
		
		.bg-greyblue & {
			
			&:hover, &.active {
				background-color: $c-green-txt;
				color: $c-white;
			}
		}
		
		.bg-rose & {
			
			&:hover, &.active {
				background-color: $c-violet;
				color: $c-white;
			}
		}
		
		.bg-violet & {
						
			&:hover, &.active {
				background-color: $c-white;
				color: $c-violet;
			}
		}
	
		&.m--violet {
						
			&:hover, &.active {
				background-color: $c-violet;
				color: $c-white;
			}
		}
	
		&.m--white {
			padding: 1rem 3rem;
			
			&:hover, &.active {
				background-color: $c-white;
				color: $c-violet;
			}
		}
		
		// &.m--contact {
						
		// 	&:hover, &.active {
		// 		background-color: #22083b;
		// 		color: $c-white;
		// 	}			
		// }
	}
}