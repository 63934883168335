
.ressources-filter {
	color: $c-violet;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	max-width: 80em;
	margin: 2rem auto 0 auto;
	padding-left: 1rem;
	padding-right: 1rem;
	
	div {
		background-color: rgba($c-violet, .09);
		color: $c-violet;
		font-size: em(20);
		padding: 1rem;
		flex: 1 1 100%;
		
		&:first-child {
			background-color: transparent;
			font-size: em(30);
		}
	}
}

.ressources {
	margin: 0;
	padding: 0;
	
	.pg__section {
		margin-top: 1rem;
	}
	
	.grid-wrapper {
		padding-top: 0;
	}
	
	.section-title {
		color: $c-green;
		font-size: em(26);
	}
	
	.metas {
		line-height: .7;
	}
	
	.metas li, .author {
		color: $c-violet;
		font-size: em(18);
	}
	
	.metas li {
		display: inline-block;
		margin-right: .5rem;
		
		&:not(:first-child)::before {
			content: '\2022';
			font-size: em(32);
			margin-right: 6px;
			vertical-align: -7px;
		}		
	}
	
	.author {
		margin-top: .5rem;
		margin-bottom: 1rem;
	}
}


@media all and(min-width : $bp-m) {
}


@media all and(min-width : $bp-l) {
	
	.ressources-filter {
		flex-wrap: nowrap;
		
		div {
			border-right: 1px solid $c-white;
			padding: 1rem 2rem;
			flex: 0 0 25%;
			
			&:first-child {
				padding-left: 0;
				flex: 1;
			}
		}
	}
}
