/**
 * ==================== @ MAP
 */

.map {
    height: 100%;
    min-height: 300px;
    position: relative;
}

.map__view {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.map__btn {
    bottom: 30px;
    left: 30px;
    position: absolute;
}

.leaflet-marker-pane {
	
	
	.leaflet-marker-icon#proximite{
		fill:  #000 !important;
		color: #000 !important;
	}
	
	.marker-cluster {
		background-clip: padding-box;
		border-radius: 20px;
	}
	
	.marker-cluster div {
		width: 30px;
		height: 30px;
		margin-left: 5px;
		margin-top: 5px;
	
		text-align: center;
		border-radius: 15px;
	}
	
	.marker-cluster span {
		line-height: 30px;
	}
	
	.marker-cluster-small,
	.marker-cluster-medium,
	.marker-cluster-large {
		background-color: rgba($c-violet, .9);
		
		div {
			background-color: $c-violet;
		}
		
		span {
			color: $c-white;
			font-size: rem(18);
			font-weight: 700;
		}
	}	
}