/**
 * ==================== @ BASE STYLES
 */

@font-face {
  font-family: 'arvo';
  src: url(path('arvo-regular.woff2', $font-path)) format('woff2');
  font-weight: $fw-regular;
  font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'arvo';
  src: url(path('arvo-bold.woff2', $font-path)) format('woff2');
  font-weight: $fw-bold;
  font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'roboto';
  src: url(path('roboto-regular.woff2', $font-path)) format('woff2');
  font-weight: $fw-regular;
  font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'roboto';
  src: url(path('roboto-bold.woff2', $font-path)) format('woff2');
  font-weight: $fw-bold;
  font-style: normal;
	font-display: swap;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
	background-color: $c-white;
	color: $c-green-txt;
  font: em($default-font-size, 16) $font-stack;
	letter-spacing: .5px;
	padding: 0;
	margin: 0;
  position: relative;

  &::before {
    background-color: $c-white;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    transition: opacity .25s, transform .25s .25s;
    width: 100%;
    z-index: 1;
  }

  &.is-overlayed {
    overflow: hidden;
  }

  &.is-nav-open {
		height: 100vh;
    overflow: hidden;

    &::before {
      opacity: 1;
      transition: opacity .25s;
      transform: translateY(0);
    }
  }
}

a {
  color: $c-black;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

strong {
	font-size: inherit;
	margin-left: 0;
}

figure {
	border-radius: $radius-24;
	display: block;
	margin: 0;
	overflow: hidden;
	position: relative;
	
	img {
		display: block;
		width: 100%;
	}
	
	picture {
		display: block;
	}
	
	figcaption {
		font-size: em(12);
	}
}

iframe { 
	border: 0; 
}

img {
  height: auto;
  max-width: 100%;
}

main {
  padding-top: 100px;
  position: relative;
  z-index: 0;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
select { border-radius: 0; }

.sv-g [class*="sv-u"] {
	font-family: $font-stack;
	letter-spacing: .1px;
}

@media screen and (min-width: $bp-m) {

  body {
    &.is-nav-open {
      height: auto;
      overflow: visible;
  
      &::before {
        content: none;
      }
    }
  }

  main {
   padding-top: 9rem;
  }
}
