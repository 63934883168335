/**
 * ==================== @ EDITO
 */
 
.edito {
	counter-reset: summary 0;
	
	.summary {
		background-color: $c-grey;
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding: .2rem 1rem 1rem 1rem;
		
		li {
			font-size: em(20);
			margin-bottom: .5rem;
			margin-left: 36px;
			position: relative;
			
			&::before {
				counter-increment: summary;	
				content: counter(summary, decimal-leading-zero);
				color: $c-green;
				font-size: 24px;
				font-weight: $fw-bold;
				font-family: $font-alt;
				margin-right: .5rem;
				min-width: 30px;
				position: absolute;
				left: -36px;
				top: -2px;
				text-align: right;
			}
			
			a {
				display: inline-block;
				position: relative;
				
				&::after {
					content: '';
					height: 1px;
					background-color: $c-green;
					width: 0;
					position: absolute;
					left: 0;
					bottom: 0;
					transition: width 250ms;
				}
			}
		}
	}
}



@media all and (min-width : $bp-m) {

	.edito-section {
		
		.edito-wrapper-slider {
			width: calc(100% + 3rem);
		}		
	}
	
	.edito {
		
		.summary {
			padding: .2rem 4rem 1rem 4rem;
			
			li a:hover::after {
				width: 100%;
			}
		}		
	}
}