@charset "UTF-8";
/**
 * Use BEM : https://en.bem.info/
 */
/**
 * ==================== @ THEME VARIABLES
 */
/**
 * ==================== @ SASS FUNCTIONS
 */
/**
 * ==================== @ SASS MIXINS
 */
/**
 * ==================== @ SASS PLACEHOLDERS
 */
.l-clearfix::before, .l-clearfix::after {
  content: " ";
  display: table; }

.l-clearfix::after {
  clear: both; }

/**
 * ==================== @ BASE STYLES
 */
@font-face {
  font-family: 'arvo';
  src: url("/assets/fonts/arvo-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'arvo';
  src: url("/assets/fonts/arvo-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'roboto';
  src: url("/assets/fonts/roboto-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'roboto';
  src: url("/assets/fonts/roboto-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  background-color: #ffffff;
  color: #0E4741;
  font: 1em "roboto", Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  padding: 0;
  margin: 0;
  position: relative; }
  body::before {
    background-color: #ffffff;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: opacity .25s, -webkit-transform .25s .25s;
    transition: opacity .25s, -webkit-transform .25s .25s;
    transition: opacity .25s, transform .25s .25s;
    transition: opacity .25s, transform .25s .25s, -webkit-transform .25s .25s;
    width: 100%;
    z-index: 1; }
  body.is-overlayed {
    overflow: hidden; }
  body.is-nav-open {
    height: 100vh;
    overflow: hidden; }
    body.is-nav-open::before {
      opacity: 1;
      -webkit-transition: opacity .25s;
      transition: opacity .25s;
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0); }

a {
  color: #3D4547;
  text-decoration: none; }

button {
  -webkit-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0; }

strong {
  font-size: inherit;
  margin-left: 0; }

figure {
  border-radius: 1.5rem;
  display: block;
  margin: 0;
  overflow: hidden;
  position: relative; }
  figure img {
    display: block;
    width: 100%; }
  figure picture {
    display: block; }
  figure figcaption {
    font-size: 0.75em; }

iframe {
  border: 0; }

img {
  height: auto;
  max-width: 100%; }

main {
  padding-top: 100px;
  position: relative;
  z-index: 0; }

table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input,
textarea,
select {
  border-radius: 0; }

.sv-g [class*="sv-u"] {
  font-family: "roboto", Helvetica, Arial, sans-serif;
  letter-spacing: .1px; }

@media screen and (min-width: 48em) {
  body.is-nav-open {
    height: auto;
    overflow: visible; }
    body.is-nav-open::before {
      content: none; }
  main {
    padding-top: 9rem; } }

/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.l-wrapper {
  letter-spacing: .1px;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 80em;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0; }

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0; }

.l-center {
  text-align: center; }

.l-right {
  text-align: right; }

.padding-t-2 {
  padding-top: 2rem; }

.padding-t-3 {
  padding-top: 3rem; }

.padding-t-4 {
  padding-top: 4rem; }

/* EMBED
----------------------------------*/
.l-embed {
  position: relative;
  padding-bottom: 56.25%;
  /* ratio 16.9 */
  height: 0; }

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.page {
  min-height: 600px;
  overflow: hidden; }

#contact-form {
  scroll-margin-top: 150px; }

.l-scrollbar {
  position: relative; }

/* Backgrounds
------------------------*/
.bg-grey {
  background-color: #EEF5F4;
  color: #0E4741; }
  .bg-grey p, .bg-grey h2, .bg-grey h3, .bg-grey h4 {
    color: #0E4741; }

.bg-greyblue {
  background-color: #E3EDF0;
  color: #0E4741; }
  .bg-greyblue p, .bg-greyblue h2, .bg-greyblue h3, .bg-greyblue h4 {
    color: #0E4741; }

.bg-rose {
  background-color: #EEE9F1;
  color: #5D2C7B; }
  .bg-rose p, .bg-rose h2, .bg-rose h3, .bg-rose h4 {
    color: #5D2C7B; }

.bg-violet {
  background-color: #5D2C7B;
  color: #ffffff; }
  .bg-violet p, .bg-violet h2, .bg-violet h3, .bg-violet h4 {
    color: #ffffff; }

.c-green {
  color: #4CB8AD; }

.c-violet {
  color: #5D2C7B; }

/* radius layout
------------------------*/
.rounded-24 {
  border-radius: 1.5rem; }

.rounded-60:not(.bg-white) {
  border-radius: 3rem; }

.rounded-120:not(.bg-white) {
  border-radius: 3rem; }

.half-rounded {
  border-radius: 0 3rem 0 3rem; }

.half-rounded-alt {
  border-radius: 3rem 0 3rem 0; }

/* specific img centered
------------------------*/
.layout-img-centered {
  border-radius: 0; }
  .layout-img-centered figure {
    border-radius: 0; }
  .layout-img-centered.rounded-60 figure {
    border-radius: 3rem; }
  .layout-img-centered.rounded-120 figure {
    border-radius: 3rem; }
  .layout-img-centered.half-rounded figure {
    border-radius: 0 3rem 0 3rem; }

@media screen and (max-width: 767px) {
  .l--desktop {
    display: none; } }

@media screen and (min-width: 64em) {
  .website-logo {
    height: 94px; }
  .l--mobile {
    display: none; }
  /* radius layout
	------------------------*/
  .rounded-60:not(.bg-white) {
    border-radius: 4rem; }
  .rounded-120:not(.bg-white) {
    border-radius: 7rem; }
  .half-rounded {
    border-radius: 0 7rem 0 7rem; }
  .half-rounded-alt {
    border-radius: 7rem 0 7rem 0; }
  /* specific img centered
	------------------------*/
  .layout-img-centered {
    border-radius: 0; }
    .layout-img-centered figure {
      border-radius: 0; }
    .layout-img-centered.rounded-60 figure {
      border-radius: 4rem; }
    .layout-img-centered.rounded-120 figure {
      border-radius: 7rem; }
    .layout-img-centered.half-rounded figure {
      border-radius: 0 7rem 0 7rem; } }

/**
 * ==================== @ ICONS
 */
.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em;
  width: 1em; }

.icon-rs a {
  border-radius: 50%;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  -webkit-transition: background-color 250ms;
  transition: background-color 250ms; }
  .icon-rs a .svg-icon {
    color: #ffffff; }

.icon-rs:last-child a {
  line-height: 31px; }

.icon-check,
.icon-close {
  border-radius: 50%;
  background-color: #ffffff;
  display: inline-block;
  height: 28px;
  line-height: 27px;
  text-align: center;
  width: 28px; }

.icon-check .check {
  color: #4CB8AD; }

.icon-close .close {
  font-size: 12px; }

@media screen and (min-width: 64em) {
  .icon-rs a:hover {
    background-color: #4CB8AD; } }

.section-seo .entry, .section-seo p, .section-seo a, .section-seo h1, .section-seo h2, .section-seo h3, .section-seo h4, .section-seo h5 {
  color: #ffffff; }

h1, h2, h3, h4, h5 {
  font-family: "arvo", Helvetica, Arial, sans-serif;
  line-height: 1.1;
  margin: .5rem 0 1rem 0; }

h1 {
  font-size: 1.875em;
  font-weight: 600;
  margin-bottom: 2rem; }
  h1.m--404 {
    font-size: 2.75em; }

h2 {
  color: #4CB8AD;
  /* default color */
  font-size: 1.5625em;
  font-weight: 600;
  line-height: 1.2;
  position: relative; }

.layout-img-txt h2:not(.notaligned),
.layout-txt-img h2:not(.notaligned) {
  margin-top: 0; }

h3 {
  color: #4CB8AD;
  /* default color */
  font-size: 1.3125em;
  font-weight: 700;
  margin: .5rem 0; }

h4 {
  color: #4CB8AD;
  /* default color */
  font-size: 1em;
  font-weight: 700;
  margin: .25rem 0; }

p {
  font-size: 0.875em;
  line-height: 1.3;
  margin: .5rem 0 1rem 0; }
  p a {
    color: inherit;
    position: relative;
    word-break: break-all;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-color: #4CB8AD;
            text-decoration-color: #4CB8AD;
    text-underline-offset: 2px;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1.4px;
    -webkit-transition: -webkit-text-decoration-color 200ms;
    transition: -webkit-text-decoration-color 200ms;
    transition: text-decoration-color 200ms;
    transition: text-decoration-color 200ms, -webkit-text-decoration-color 200ms; }
    p a:hover {
      -webkit-text-decoration-color: #0E4741;
              text-decoration-color: #0E4741; }

@media all and (min-width: 48em) {
  h1 {
    font-size: 2.8125em; }
    h1.m--404 {
      font-size: 5em; }
  h2 {
    font-size: 2.5em; }
  h3 {
    font-size: 1.75em; }
  h4 {
    font-size: 1.25em; }
  p {
    font-size: 1.25em; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
        transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
        transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
        transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/**
 * ==================== @ CTA
 */
.cta-wrapper-desktop {
  max-width: 82em;
  margin: 0 auto;
  width: 100%; }

/* Accessyway
--------------------*/
.cta-accessibility {
  padding-right: 0.5rem;
  position: fixed;
  bottom: 2.5rem;
  right: -webkit-calc(50vw - 40em);
  right: calc(50vw - 40em);
  z-index: 20; }
  .cta-accessibility .breizh-acsb-trigger {
    height: 45px;
    border-radius: 99px;
    min-width: 45px;
    padding: 0;
    border: none;
    background-color: #5D2C7B;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .cta-accessibility .breizh-acsb-trigger span {
      color: #fff;
      display: inline-block;
      font-weight: 700;
      padding: 0;
      text-transform: uppercase;
      overflow: hidden;
      width: 0;
      -webkit-transition: width 350ms, padding 360ms;
      transition: width 350ms, padding 360ms; }
    .cta-accessibility .breizh-acsb-trigger:hover span {
      padding: 8px 0 8px 16px;
      width: 118px; }
  .cta-accessibility .acsb-trigger-svg {
    height: 30px;
    width: 24px;
    margin: 0 10px;
    fill: white !important; }

/**
 * ==================== @ HEADER
 */
.header {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100; }
  .header-top {
    background-color: #e7f2f4; }
    .header-top__inner {
      height: 40px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      gap: 1.2rem;
      margin: 0 auto;
      max-width: 80em;
      padding-right: .5rem; }
      .header-top__inner a {
        color: #16808F; }
        .header-top__inner a:hover {
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          text-underline-offset: 4px; }
      .header-top__inner li:last-child a {
        font-weight: 700; }
        .header-top__inner li:last-child a .svg-icon {
          font-size: 0.625rem;
          vertical-align: 0; }
  .header-inner {
    height: 110px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .header-right {
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    border: 0;
    border-radius: 999px;
    background-color: #5D2C7B;
    color: #ffffff;
    padding-right: 1.5rem;
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    padding-bottom: 0.25rem; }
  .header-logo {
    position: absolute;
    left: 0;
    top: 26px; }
    .header-logo .website-logo {
      display: block; }
      .header-logo .website-logo img {
        height: 54px;
        width: auto;
        -webkit-transition: height 250ms, margin 250ms;
        transition: height 250ms, margin 250ms; }
  .header-contact {
    margin-right: 1.5rem; }
  .header-menu {
    padding-left: 15px; }
    .header-menu .menu-toggle {
      color: #ffffff;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 1.5em;
      line-height: 1; }
      .header-menu .menu-toggle .svg-icon {
        vertical-align: middle; }
      .header-menu .menu-toggle .close {
        display: none; }
      .header-menu .menu-toggle:focus {
        outline: 1px solid #fff;
        outline-offset: 4px;
        border-radius: 2px; }
      .header-menu .menu-toggle span {
        font-size: 0.58333em;
        font-weight: 700;
        margin-left: .5rem;
        text-transform: uppercase; }
    .is-nav-open .header-menu .burger {
      display: none; }
    .is-nav-open .header-menu .close {
      display: block; }
  .header-top-menu {
    padding-right: 1.5rem;
    display: none; }
    .header-top-menu .top-menu__items {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      gap: .5rem; }
    .header-top-menu .top-menu__item a, .header-top-menu .top-menu__item span, .header-top-menu .top-menu__subitem a, .header-top-menu .top-menu__subitem span {
      color: #5D2C7B;
      cursor: pointer;
      font-size: 1rem;
      display: block; }
    .header-top-menu .top-menu__item {
      position: relative;
      -webkit-transition: background-color 250ms;
      transition: background-color 250ms;
      z-index: 2; }
      .header-top-menu .top-menu__item > a, .header-top-menu .top-menu__item > span {
        font-size: 1.125rem;
        font-weight: 700;
        padding: .5rem .75rem;
        position: relative; }
      .header-top-menu .top-menu__item > span {
        padding-right: 2rem; }
        .header-top-menu .top-menu__item > span::after {
          content: '';
          font-size: 0.75rem;
          position: absolute;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%235D2C7B' d='M26.581 15.652c-0.081-0.667-0.38-1.253-0.821-1.696l0 0-12.476-12.8c-0.605-0.642-1.439-1.062-2.37-1.13l-0.012-0.001c-0.104-0.010-0.225-0.016-0.348-0.016-0.838 0-1.613 0.273-2.24 0.734l0.010-0.007c-0.676 0.49-1.132 1.248-1.212 2.115l-0.001 0.011c-0.003 0.049-0.005 0.106-0.005 0.164 0 0.82 0.347 1.559 0.903 2.079l0.002 0.002 10.556 10.834-10.549 10.83c-0.561 0.521-0.911 1.263-0.911 2.086 0 0.055 0.002 0.11 0.005 0.165l-0-0.008c0.076 0.88 0.53 1.641 1.197 2.128l0.008 0.006c0.617 0.454 1.392 0.727 2.23 0.727 0.122 0 0.244-0.006 0.363-0.017l-0.015 0.001c0.943-0.068 1.777-0.489 2.381-1.129l0.002-0.002 12.476-12.8c0.515-0.511 0.834-1.219 0.834-2.002 0-0.094-0.005-0.187-0.014-0.278l0.001 0.012z'%3E%3C/path%3E%3C/svg%3E");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 1em;
          width: 1em;
          -webkit-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
                  transform: rotate(90deg);
          right: 8px;
          top: 14px; }
      .header-top-menu .top-menu__item:hover {
        background-color: #EEE9F1; }
        .header-top-menu .top-menu__item:hover .top-menu__subitems {
          height: -webkit-max-content;
          height: max-content;
          opacity: 1;
          -webkit-transition: opacity 250ms;
          transition: opacity 250ms; }
    .header-top-menu .top-menu__subitems {
      background-color: #EEE9F1;
      position: absolute;
      opacity: 0;
      width: -webkit-max-content;
      width: max-content;
      height: 0;
      overflow: hidden; }
      .header-top-menu .top-menu__subitems a {
        margin: .5rem;
        padding: .5rem;
        -webkit-transition: background-color 250ms, color 250ms;
        transition: background-color 250ms, color 250ms; }

.header-search-and-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .header-search-and-menu .header-search {
    background-color: #EEE9F1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 99px;
    margin-right: -3rem;
    max-height: 54px;
    position: relative;
    overflow: hidden;
    z-index: 2; }
    .header-search-and-menu .header-search .form-input[type=text] {
      padding: 0;
      margin: 13px 20px;
      max-width: 210px;
      -webkit-transition: width 360ms;
      transition: width 360ms; }
      .header-search-and-menu .header-search .form-input[type=text]:focus {
        outline: 1px solid #5D2C7B;
        outline-offset: 4px; }
    .header-search-and-menu .header-search .button-submit {
      height: 48px;
      width: 48px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      right: 4px;
      top: 3px; }
      .header-search-and-menu .header-search .button-submit:focus {
        outline: 1px solid #5D2C7B;
        outline-offset: 1px; }
    .header-search-and-menu .header-search .svg-icon {
      color: #5D2C7B;
      font-size: 1.75rem; }
  .header-search-and-menu .header-menu {
    background-color: #5D2C7B;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 99px;
    padding: 14px 1.5rem 14px 4rem; }
    .header-search-and-menu .header-menu span {
      font-size: 0.9375rem;
      text-transform: uppercase; }

@media all and (max-width: 560px) {
  .header-menu span {
    display: none; }
  .header-contact {
    display: none; }
  .header-search-and-menu {
    z-index: 100; }
    .header-search-and-menu .header-search .form-input[type=text] {
      width: 15px; }
    .header-search-and-menu .header-search.active .form-input[type=text], .is-nav-open .header-search-and-menu .header-search .form-input[type=text] {
      width: 220px; } }

@media all and (min-width: 40em) {
  .header-accessibility {
    padding-right: 1.5rem; } }

@media all and (min-width: 48em) {
  .header-inner {
    max-width: 80em;
    height: auto; }
  .header-top-menu .top-menu__subitems a:hover {
    background-color: #5D2C7B;
    color: #fff; } }

@media all and (min-width: 64em) {
  .header-logo {
    top: 0; }
    .header-logo .website-logo img {
      height: 110px; }
    .is-shrink .header-logo .website-logo img {
      height: 72px;
      margin-top: .5rem; } }

@media all and (min-width: 80em) {
  .header-inner {
    margin: 0 auto; }
  .header-top-menu {
    display: inherit; } }

/**
 * ==================== @ FOOTER
 */
.footer-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 2rem 0; }
  .footer-inner .website-logo-footer img {
    max-height: 60px;
    margin-bottom: .5rem;
    width: auto; }

.footer-inner__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  margin-bottom: 1.5rem; }
  .footer-inner__item a {
    color: #ffffff; }
  .footer-inner__item.footer-rs a {
    margin-right: 1.2rem;
    color: #ffffff; }
    .footer-inner__item.footer-rs a:first-child {
      vertical-align: -1px; }
  .footer-inner__item.footer-rs .svg-icon {
    font-size: 1.25em; }
  .footer-inner__item:last-child {
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 0; }

@media screen and (min-width: 64em) {
  .footer-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2rem 0; }
    .footer-inner .website-logo-footer img {
      max-height: 72px;
      margin-bottom: 0; }
  .footer-inner__item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    margin-bottom: 0; }
    .footer-inner__item.footer-rs {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      text-align: right; }
      .footer-inner__item.footer-rs a {
        color: rgba(255, 255, 255, 0.6);
        -webkit-transition: color 250ms;
        transition: color 250ms; }
        .footer-inner__item.footer-rs a:hover {
          color: #ffffff; }
    .footer-inner__item:last-child {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      text-align: right;
      padding-top: 4px; } }

/**
 * ==================== @ BREADCRUMB
*/
.breadcrumb {
  padding: .2rem 0; }

.breadcrumb__list {
  list-style: none;
  padding: 0; }
  .breadcrumb__list li {
    display: inline-block;
    font-size: 1em;
    margin-right: .75rem; }
    .breadcrumb__list li .home {
      color: #ffffff;
      font-size: 1.5em;
      vertical-align: -6px; }
    .breadcrumb__list li .arrow-right {
      color: #ffffff;
      font-size: 0.5625em;
      margin-right: .3rem;
      vertical-align: middle; }
    .breadcrumb__list li span, .breadcrumb__list li a {
      color: #ffffff; }

/**
 * ==================== @ BUTTONS
 */
.btn-wrapper {
  padding: 1rem 0; }
  .btn-wrapper.m--center {
    text-align: center; }
  .btn-wrapper.m--right {
    text-align: right; }

.btn {
  background-color: transparent;
  border: 4px solid #4CB8AD;
  border-radius: 6px;
  color: #4CB8AD;
  display: inline-block;
  font-size: 1.125em;
  font-weight: 700;
  padding: .75rem 1rem;
  text-transform: uppercase;
  -webkit-transition: background-color 250ms, color 250ms;
  transition: background-color 250ms, color 250ms;
  text-align: center; }
  .bg-grey .btn {
    background-color: #ffffff;
    border: 4px solid #4CB8AD;
    color: #4CB8AD; }
  .bg-greyblue .btn {
    background-color: #ffffff;
    border: 4px solid #0E4741;
    color: #0E4741; }
  .bg-rose .btn {
    background-color: #ffffff;
    border: 4px solid #5D2C7B;
    color: #5D2C7B; }
  .bg-violet .btn {
    border: 4px solid #ffffff;
    color: #ffffff; }
  .btn.m--violet {
    background-color: #ffffff;
    border: 4px solid #5D2C7B;
    color: #5D2C7B; }
  .btn.m--contact {
    border: 0;
    border-radius: 999px;
    background-color: #ffffff;
    color: #5D2C7B;
    font-size: 1em;
    line-height: 1;
    padding: .7rem 1.5rem .6rem 1.5rem; }
  .btn.m--white {
    background-color: transparent;
    border: 4px solid #ffffff;
    color: #ffffff; }
  .btn.m--smaller {
    font-size: 0.875em; }

@media all and (min-width: 64em) {
  .btn {
    padding: 1rem 2rem;
    text-align: left; }
    .bg-grey .btn:hover, .bg-grey .btn.active {
      background-color: #4CB8AD;
      color: #ffffff; }
    .bg-greyblue .btn:hover, .bg-greyblue .btn.active {
      background-color: #0E4741;
      color: #ffffff; }
    .bg-rose .btn:hover, .bg-rose .btn.active {
      background-color: #5D2C7B;
      color: #ffffff; }
    .bg-violet .btn:hover, .bg-violet .btn.active {
      background-color: #ffffff;
      color: #5D2C7B; }
    .btn.m--violet:hover, .btn.m--violet.active {
      background-color: #5D2C7B;
      color: #ffffff; }
    .btn.m--white {
      padding: 1rem 3rem; }
      .btn.m--white:hover, .btn.m--white.active {
        background-color: #ffffff;
        color: #5D2C7B; } }

/**
 * ==================== @ MENU NAV
 */
.nav {
  display: none;
  background-color: #5D2C7B;
  padding-top: 4rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 5rem;
  width: 100vw;
  will-change: transform;
  z-index: 99; }

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slideIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slideOut {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes slideOut {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@-webkit-keyframes fadeIn {
  from {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@keyframes fadeIn {
  from {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
    opacity: 0; } }

@keyframes fadeOut {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
    opacity: 0; } }
  .nav[aria-hidden="false"] {
    -webkit-animation: slideIn 360ms ease-in-out;
            animation: slideIn 360ms ease-in-out; }
  .nav[aria-hidden="true"] {
    -webkit-animation: slideOut 360ms ease-in-out;
            animation: slideOut 360ms ease-in-out; }
  .nav .btn-close {
    right: 2rem; }
  .nav .btn-close, .nav .submenu-back, .nav .js-menu-close {
    color: #ffffff;
    position: absolute;
    top: 38px; }
    .nav .btn-close:focus, .nav .submenu-back:focus, .nav .js-menu-close:focus {
      outline: 1px solid #fff;
      outline-offset: 4px;
      border-radius: 2px; }
    .nav .btn-close span, .nav .submenu-back span, .nav .js-menu-close span {
      display: inline-block;
      font-size: 1.125em;
      font-weight: 700;
      margin-right: .5rem;
      text-transform: uppercase; }
    .nav .btn-close .svg-icon, .nav .submenu-back .svg-icon, .nav .js-menu-close .svg-icon {
      font-size: 1.125em;
      vertical-align: -2px; }
  .nav .submenu-back {
    display: none;
    left: 1rem;
    top: 6rem;
    z-index: 4; }
    .nav .submenu-back[aria-hidden="false"] {
      -webkit-animation: fadeIn 360ms ease-in-out;
              animation: fadeIn 360ms ease-in-out; }
    .nav .submenu-back[aria-hidden="true"] {
      -webkit-animation: fadeOut 360ms ease-in-out;
              animation: fadeOut 360ms ease-in-out; }
    .nav .submenu-back span {
      margin-left: .5rem; }
    .nav .submenu-back.active {
      display: block; }
  .nav .js-menu-close {
    display: none; }
    .nav .js-menu-close .svg-icon {
      margin-right: .5rem; }
  .nav .nav-menu {
    padding: 1rem 1.5rem 1rem 1rem;
    text-align: right; }
    .nav .nav-menu.m--bottom {
      padding-right: 2.5rem; }
    .nav .nav-menu .menu-item > :is(a, button) {
      padding: .5rem 1rem;
      border-radius: 99px;
      -webkit-transition: color 250ms, background-color 250ms;
      transition: color 250ms, background-color 250ms; }
      .nav .nav-menu .menu-item > :is(a, button):focus {
        outline: 1px solid #fff;
        outline-offset: 2px; }
    .nav .nav-menu .menu-item:last-child {
      margin-bottom: 0; }
    .nav .nav-menu .menu-item.has-children > button {
      cursor: pointer;
      font-weight: normal; }
    .nav .nav-menu a, .nav .nav-menu button, .nav .nav-menu span {
      display: block;
      width: 100%;
      color: #ffffff;
      font-size: 1.125em;
      font-family: "arvo", Helvetica, Arial, sans-serif;
      text-align: inherit; }
    .nav .nav-menu span {
      font-weight: 700;
      padding-right: 1rem; }
    .nav .nav-menu.m--first {
      background-color: #5D2C7B;
      padding-top: 1rem; }
      .nav .nav-menu.m--first .nav-submenu {
        background-color: #5D2C7B; }
    .nav .nav-menu.m--second {
      background-color: #5D2C7B; }
      .nav .nav-menu.m--second .nav-submenu {
        background-color: #5D2C7B; }
    .nav .nav-menu.m--third {
      background-color: #5D2C7B; }
      .nav .nav-menu.m--third .nav-submenu {
        background-color: #5D2C7B; }
  .nav .nav-submenu {
    display: none;
    padding: 8rem 2rem 1rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    will-change: transform;
    z-index: 2; }
    .nav .nav-submenu[aria-hidden="false"] {
      -webkit-animation: slideIn 360ms ease-in-out;
              animation: slideIn 360ms ease-in-out; }
    .nav .nav-submenu[aria-hidden="true"] {
      -webkit-animation: slideOut 360ms ease-in-out;
              animation: slideOut 360ms ease-in-out; }
    .nav .nav-submenu.is-subnav-open {
      display: block; }
  .is-nav-open .nav {
    display: block;
    min-height: 400px; }

@media all and (min-width: 64em) {
  .nav {
    border-bottom-left-radius: 120px;
    bottom: auto;
    top: 40px;
    padding-top: 4rem;
    overflow: hidden;
    width: 400px; }
    .nav .btn-close {
      right: 4rem; }
    .nav .submenu-back {
      top: 2.5rem;
      left: 2rem; }
    .nav .js-menu-close {
      display: block;
      top: 2.5rem;
      right: 3rem; }
    .nav .nav-menu {
      padding: 2rem; }
      .nav .nav-menu .menu-item > a:hover {
        background-color: #EEE9F1;
        color: #5D2C7B; }
      .nav .nav-menu .menu-item.has-children > button:hover {
        background-color: #EEE9F1;
        color: #5D2C7B; }
    .nav .nav-submenu {
      padding: 5rem 2rem 1rem 1rem;
      width: 100%; } }

@media all and (min-width: 1790px) {
  .nav {
    width: 460px; } }

/**
 * ==================== @ MAP
 */
.map {
  height: 100%;
  min-height: 300px;
  position: relative; }

.map__view {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.map__btn {
  bottom: 30px;
  left: 30px;
  position: absolute; }

.leaflet-marker-pane .leaflet-marker-icon#proximite {
  fill: #000 !important;
  color: #000 !important; }

.leaflet-marker-pane .marker-cluster {
  background-clip: padding-box;
  border-radius: 20px; }

.leaflet-marker-pane .marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px; }

.leaflet-marker-pane .marker-cluster span {
  line-height: 30px; }

.leaflet-marker-pane .marker-cluster-small,
.leaflet-marker-pane .marker-cluster-medium,
.leaflet-marker-pane .marker-cluster-large {
  background-color: rgba(93, 44, 123, 0.9); }
  .leaflet-marker-pane .marker-cluster-small div,
  .leaflet-marker-pane .marker-cluster-medium div,
  .leaflet-marker-pane .marker-cluster-large div {
    background-color: #5D2C7B; }
  .leaflet-marker-pane .marker-cluster-small span,
  .leaflet-marker-pane .marker-cluster-medium span,
  .leaflet-marker-pane .marker-cluster-large span {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 700; }

/**
 * ==================== @ PAGINATION - NEWS
*/
.pagination {
  margin: 2rem 0;
  width: 100%; }
  .pagination .pagination-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto; }
    .pagination .pagination-list .page-items {
      margin: 0 .3rem;
      text-align: center; }
      .pagination .pagination-list .page-items a {
        border: 3px solid #ffffff;
        border-radius: 12px;
        color: #5D2C7B;
        font-weight: 700;
        display: block;
        line-height: 40px;
        text-align: center;
        height: 44px;
        width: 44px;
        -webkit-transition: border-color 150ms;
        transition: border-color 150ms; }
      .pagination .pagination-list .page-items .svg-icon {
        vertical-align: -2px; }
      .pagination .pagination-list .page-items.m--active a, .pagination .pagination-list .page-items:hover a {
        border: 3px solid #5D2C7B; }

/**
 * ==================== @ GENERIC FORMS
*/
.form-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  position: relative; }

.form-field {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  position: relative;
  padding-bottom: 2rem; }
  .form-field.m--right {
    text-align: right; }
  .form-field.m--1-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
  .form-field.m--2-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
  .form-field label {
    color: #5D2C7B;
    display: inline-block;
    font-size: 1.25em;
    padding-bottom: .5rem; }

.form-input[type=text],
.form-input[type=email],
textarea.form-input {
  border: 0;
  outline: 0;
  padding: 1rem;
  background-color: #EFEBF2;
  font-size: 1.25em;
  font-family: inherit;
  width: 100%;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
  resize: none;
  color: #5D2C7B; }
  .form-input[type=text]::-webkit-input-placeholder,
  .form-input[type=email]::-webkit-input-placeholder,
  textarea.form-input::-webkit-input-placeholder {
    color: #5D2C7B;
    opacity: .95; }
  .form-input[type=text]:-ms-input-placeholder,
  .form-input[type=email]:-ms-input-placeholder,
  textarea.form-input:-ms-input-placeholder {
    color: #5D2C7B;
    opacity: .95; }
  .form-input[type=text]::placeholder,
  .form-input[type=email]::placeholder,
  textarea.form-input::placeholder {
    color: #5D2C7B;
    opacity: .95; }

.form-input[type=submit] {
  background-color: #ffffff;
  border: 4px solid #5D2C7B;
  border-radius: 6px;
  color: #5D2C7B;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125em;
  font-weight: 700;
  padding: 1rem 3rem;
  text-transform: uppercase; }
  .form-input[type=submit]:focus {
    outline: 1px solid #5D2C7B;
    outline-offset: 1px; }

.form-message {
  background-color: #ffffff;
  padding: 2rem;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center;
  -webkit-transition: opacity 450ms;
  transition: opacity 450ms;
  z-index: -1; }
  .form-message span {
    font-size: 1.25em; }
    .form-message span::before {
      content: "✓";
      height: 14px;
      width: 14px;
      margin-right: .5rem; }
    .form-message span.success {
      color: #4CB8AD; }
    .form-message span.failed {
      color: #EB8941; }
  .form-message.active {
    opacity: 1;
    z-index: 2; }

/* Custom design Select
---------------------*/
select {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #efebf2;
  background-image: none;
  color: #5D2C7B;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.25rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 .5em;
  width: 100%; }

select::-ms-expand {
  display: none; }

.select {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  height: 3em;
  line-height: 3;
  background: #5d2c7b;
  overflow: hidden;
  border-radius: 0; }
  .select select:focus {
    outline: 1px solid #5D2C7B;
    outline-offset: -1px; }
  .select.m--violet select {
    background: #5D2C7B;
    color: #ffffff; }
    .select.m--violet select:focus {
      outline-color: #fff; }
  .select.m--violet::after {
    background: #5D2C7B;
    color: #ffffff; }

.select::after {
  content: '\25BC';
  background: #efebf2;
  color: #5D2C7B;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  padding: 0 1em;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  transition: .25s all ease; }

/* End custom select
---------------------*/
/* Some adjusts
---------------------*/
.form-contact .select::after {
  margin-right: 1rem; }

.form-contact select {
  font-size: 0.88889em;
  padding: 1em; }

.form-contact textarea.form-input {
  min-height: 180px; }

@media screen and (min-width: 40em) {
  .form-field {
    /* Search header
		----------------*/ }
    .form-field.m--search {
      width: 200px; } }

@media screen and (min-width: 48em) {
  .form-field {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    position: relative;
    padding-bottom: 2rem; }
    .form-field.m--right {
      text-align: right; }
    .form-field.m--1-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33%;
          -ms-flex: 0 0 33%;
              flex: 0 0 33%; }
    .form-field.m--2-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66%;
          -ms-flex: 0 0 66%;
              flex: 0 0 66%; }
  .form-input[type=submit] {
    -webkit-transition: background-color 250ms, color 250ms;
    transition: background-color 250ms, color 250ms; }
    .form-input[type=submit]:hover {
      background-color: #5D2C7B;
      color: #ffffff; } }

@media screen and (min-width: 860px) {
  .form-field {
    /* Search header
		----------------*/ }
    .form-field.m--search {
      width: 300px; } }

@media screen and (min-width: 64em) {
  .select {
    font-size: 1.375em; }
  .dropdown .select {
    min-width: 16rem;
    max-width: 16rem; } }

/**
 * ==================== @ POPUPS
 */
.mfp-bg {
  background-color: #000;
  opacity: .8; }

.mfp-container .mfp-content {
  max-width: 800px; }

.popup {
  background-color: #fff;
  margin: 30px 0;
  padding: 8%;
  position: relative; }

/**
 * ==================== @ GRID LAYOUT
 */
.grid-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 80em;
  margin: 0 auto; }
  .grid-wrapper.wrapper-10-cols {
    max-width: 65em; }
  .grid-wrapper.wrapper-8-cols {
    max-width: 52em; }

.grid-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  overflow: hidden;
  padding: 1.5rem; }
  .grid-layout .grid-item {
    grid-column: 1 / -1; }
  .grid-layout .grid-item.section-title {
    text-align: center; }
  .bg-white .grid-layout, .grid-layout.bg-white {
    padding: 0 0 .5rem 0; }
  .grid-layout.full-width {
    padding: 0; }
  .grid-layout.layout-two-cols {
    gap: 1rem;
    padding: 0; }
  .grid-layout.layout-2cols .btn-wrapper {
    grid-column: 1 / -1 !important; }
  .grid-layout.layout-three-cols {
    gap: 1rem;
    padding: .5rem 2rem; }
  .grid-layout.layout-video {
    padding: 1.5rem 0; }
    .grid-layout.layout-video .grid-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      gap: .75rem; }
      .grid-layout.layout-video .grid-item .video_title {
        font-size: 1.375rem;
        font-family: "arvo", Helvetica, Arial, sans-serif;
        font-weight: 600;
        padding: 0 1rem; }
      .grid-layout.layout-video .grid-item .video_caption {
        font-size: 1rem;
        padding: 0 1rem; }
        .grid-layout.layout-video .grid-item .video_caption p {
          font-size: 1rem;
          margin: 0; }
  .m--snap-left .grid-layout,
  .m--snap-right .grid-layout {
    padding: 0; }
    .m--snap-left .grid-layout .grid-item,
    .m--snap-right .grid-layout .grid-item {
      padding: 0; }
  .grid-layout.layout-img-centered.full-width .grid-item {
    padding: 2rem 0; }

@media screen and (max-width: 1023px) {
  .grid-wrapper.m--snap-right {
    padding-left: 0;
    padding-right: 0; }
    .grid-wrapper.m--snap-right .list-wrapper {
      grid-row: 2 / auto;
      padding-left: 1rem;
      padding-right: 1rem; } }

/* === 1024px === */
@media screen and (min-width: 64em) {
  .grid-wrapper.m--boxed {
    max-width: 48em;
    margin: 0 auto; }
  .grid-wrapper.m--snap-left, .grid-wrapper.m--snap-right {
    position: relative; }
  .grid-layout {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, auto);
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    padding: 3rem 4rem 2rem 4rem;
    /* Specific page contact
		------------------------*/ }
    .bg-white .grid-layout, .grid-layout.bg-white {
      padding: 0 0 1rem 0; }
    .grid-layout.bloc-head {
      padding: 2rem 0; }
      .grid-layout.bloc-head .grid-item {
        padding: 0 1rem; }
    .grid-layout.layout-txt-img .grid-item:first-child {
      grid-column: span 7;
      padding: 1rem; }
    .grid-layout.layout-txt-img .grid-item:last-child {
      grid-column: span 5; }
    .grid-layout.layout-txt-img.bloc-head .grid-item:first-child {
      grid-column: 1 / span 7;
      grid-row: 1 / auto; }
    .grid-layout.layout-txt-img.bloc-head .grid-item:nth-of-type(2) {
      grid-column: 8 / span 5;
      grid-row: 1 / span 2; }
    .grid-layout.layout-txt-img.bloc-head .grid-item:last-child {
      grid-column: 1 / span 7;
      grid-row: 2 / auto; }
    .grid-layout.layout-img-txt .grid-item:first-child {
      grid-column: span 5; }
    .grid-layout.layout-img-txt .grid-item:last-child {
      grid-column: span 7;
      padding: 1rem; }
    .grid-layout.layout-img-txt.bloc-head .grid-item:first-child {
      grid-column: 6 / span 7;
      grid-row: 1 / auto; }
    .grid-layout.layout-img-txt.bloc-head .grid-item:nth-of-type(2) {
      grid-column: 1 / span 5;
      grid-row: 1 / span 2; }
    .grid-layout.layout-img-txt.bloc-head .grid-item:last-child {
      grid-column: 6 / span 7;
      grid-row: 2 / auto; }
    .grid-layout.layout-txt .grid-item, .grid-layout.layout-boxed-8 .grid-item, .grid-layout.layout-img-centered .grid-item {
      grid-column: 3 / -3;
      padding: 0; }
    .grid-layout.layout-img-centered.full-width .grid-item {
      padding: 2rem 0; }
    .grid-layout.layout-txt.rounded-60 .grid-item, .grid-layout.layout-txt.rounded-120 .grid-item {
      grid-column: 1 / -1;
      padding: 0; }
    .grid-layout.layout-boxed-10 .grid-item {
      grid-column: 2 / -2; }
    .grid-layout.layout-txt.full-width {
      padding: 3rem 0; }
    .grid-layout.layout-2cols .grid-item:not(.full-width) {
      grid-column: span 6; }
    .grid-layout.layout-two-cols {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem 3rem; }
      .grid-layout.layout-two-cols .grid-item {
        grid-column: span 1; }
    .grid-layout.layout-three-cols {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.5rem;
      padding: 2rem 0; }
      .grid-layout.layout-three-cols .grid-item {
        grid-column: span 1; }
      .grid-layout.layout-three-cols .grid-item.m--full {
        grid-column: span 3; }
    .m--snap-left .grid-layout .grid-item:nth-of-type(1) {
      grid-column: 1 / span 5;
      position: absolute;
      left: 0;
      width: -webkit-calc((100vw * 5) / 12);
      width: calc((100vw * 5) / 12);
      -webkit-transform: translateX(-webkit-calc((100vw - 1280px)/2 * -1));
          -ms-transform: translateX(calc((100vw - 1280px)/2 * -1));
              transform: translateX(calc((100vw - 1280px)/2 * -1)); }
    .m--snap-left .grid-layout .grid-item:nth-of-type(2) {
      grid-column: 6 / span 7; }
    .m--snap-right .grid-layout .list-wrapper {
      grid-column: 1 / span 6; }
    .m--snap-right .grid-layout .map-wrapper {
      grid-column: 7 / span 6; }
      .m--snap-right .grid-layout .map-wrapper .js-map-view {
        position: relative; }
    .grid-layout .grid-item.section-title {
      grid-column: 1 / -1;
      padding: 0;
      text-align: center; }
      .grid-layout .grid-item.section-title h2 {
        margin: 0; }
    .grid-layout .grid-item.vcenter {
      -webkit-align-self: center;
          -ms-flex-item-align: center;
              align-self: center; } }

@media screen and (min-width: 80em) {
  .m--snap-right .grid-layout .map-wrapper {
    position: absolute;
    right: 0;
    -webkit-transform: translateX(-webkit-calc((100vw - 1280px)/2));
        -ms-transform: translateX(calc((100vw - 1280px)/2));
            transform: translateX(calc((100vw - 1280px)/2));
    width: 50vw; }
    .m--snap-right .grid-layout .map-wrapper .js-map-view {
      min-width: 100%;
      position: absolute; }
  .m--snap-right .grid-layout.layout-video {
    padding: 3rem 4rem 2rem 4rem; }
    .m--snap-right .grid-layout.layout-video .grid-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      gap: .75rem; }
      .m--snap-right .grid-layout.layout-video .grid-item .video_title {
        font-size: 1.25rem;
        padding: 0 1rem; }
      .m--snap-right .grid-layout.layout-video .grid-item .video_caption {
        font-size: 1rem;
        padding: 0 1rem; }
        .m--snap-right .grid-layout.layout-video .grid-item .video_caption p {
          font-size: 1rem;
          margin: 0; } }

/**
 * ==================== @ BLOCS
*/
.blocks-list {
  max-width: 100%;
  padding-bottom: -webkit-calc(100vh - 340px);
  padding-bottom: calc(100vh - 340px); }
  .blocks-list .card {
    max-width: 100%;
    border-bottom: 1px solid #3D4547;
    min-height: 110px;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
    word-break: break-word; }
    .blocks-list .card.m--structure {
      min-height: 112px; }
    .blocks-list .card .card-name {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      width: 100%;
      background-color: #ffffff;
      cursor: pointer;
      font-size: 1.3125em;
      font-family: "arvo", Helvetica, Arial, sans-serif;
      font-weight: 700;
      text-align: left;
      padding-right: 2.5rem;
      position: relative;
      z-index: 2; }
      .blocks-list .card .card-name:focus {
        outline: 1px solid #3D4547;
        outline-offset: -1px; }
      .blocks-list .card .card-name .speciality {
        color: #5D2C7B;
        font-family: "roboto", Helvetica, Arial, sans-serif;
        display: block;
        font-size: 0.83333em; }
      .blocks-list .card .card-name::before, .blocks-list .card .card-name::after {
        content: '';
        height: 4px;
        width: 40px;
        background-color: #5D2C7B;
        position: absolute;
        top: 1rem;
        right: 0;
        -webkit-transition: -webkit-transform 350ms;
        transition: -webkit-transform 350ms;
        transition: transform 350ms;
        transition: transform 350ms, -webkit-transform 350ms; }
      .blocks-list .card .card-name::before {
        -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
                transform: rotate(-90deg); }
      .blocks-list .card .card-name::after {
        -webkit-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
                transform: rotate(-180deg); }
    .blocks-list .card .card-metas {
      display: none;
      max-width: 100%;
      position: relative;
      z-index: 1; }
      .blocks-list .card .card-metas > div {
        font-size: 1.25em;
        margin-bottom: .5rem; }
        .blocks-list .card .card-metas > div p {
          font-size: 0.95238em;
          line-height: 1.1;
          margin-top: .1rem;
          margin-bottom: .2rem; }
        .blocks-list .card .card-metas > div strong {
          color: #5D2C7B; }
      .blocks-list .card .card-metas .contact {
        line-height: 1.3;
        max-width: 100%;
        margin-bottom: 2rem;
        position: relative; }
        .blocks-list .card .card-metas .contact a, .blocks-list .card .card-metas .contact span, .blocks-list .card .card-metas .contact p {
          display: inline-block;
          font-size: 0.95238em;
          font-weight: 700; }
        .blocks-list .card .card-metas .contact .btn.m--rdv {
          display: inline-block;
          padding: .5rem 1rem;
          position: absolute;
          top: -48px;
          right: 0; }
        .blocks-list .card .card-metas .contact .span {
          max-width: 90%;
          overflow-wrap: anywhere; }
        .blocks-list .card .card-metas .contact a:hover {
          text-decoration: underline; }
    .blocks-list .card .adress {
      font-size: 0.95238em;
      margin-bottom: .85rem;
      position: relative; }
      .blocks-list .card .adress picture {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 110px; }
    .blocks-list .card .mission {
      font-size: 0.95238em;
      padding-bottom: .85rem; }
    .blocks-list .card.active .card-name::before {
      -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
              transform: rotate(0); }
    .blocks-list .card.active .card-name::after {
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg); }
    .blocks-list .card.active .card-metas {
      display: block; }

/* Bloc logo in flexbox / 7 Cols
-------------------------------*/
.grid-logos {
  padding: 1rem 0; }
  .grid-logos .grid-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    gap: 2rem; }
  .grid-logos .bloc-logo {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    text-align: center; }
    .grid-logos .bloc-logo img {
      max-height: 84px; }

/* Bloc download
----------------*/
.grid-item.download {
  position: relative; }
  .grid-item.download h2 {
    padding-right: 3.1rem;
    word-break: break-all; }
  .grid-item.download .icn-download {
    position: absolute;
    right: 3rem;
    top: 2.5rem; }
    .grid-item.download .icn-download .svg-icon {
      font-size: 3em; }

@media all and (min-width: 48em) {
  .blocks-list .card {
    min-height: 84px; }
    .blocks-list .card.m--structure {
      min-height: 112px; }
  /* Bloc logo
	------------*/
  .grid-logos .bloc-logo {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 13.33%;
        -ms-flex: 0 0 13.33%;
            flex: 0 0 13.33%; }
    .grid-logos .bloc-logo img {
      max-height: 140px; } }

/*
 * ==================== @ SLICK THEME
 */
.slider {
  opacity: 0;
  -webkit-transition: opacity 400ms ease-out;
  transition: opacity 400ms ease-out; }
  .slider.slick-initialized {
    opacity: 1; }
  .slider .slider__slide {
    border-radius: 1.5rem;
    margin: 1rem;
    position: relative;
    overflow: hidden; }
    .slider .slider__slide img {
      display: block; }
  .slider .slick-arrow {
    position: absolute;
    height: 48px;
    width: 48px;
    border: 3px solid #ffffff;
    border-radius: 12px;
    font-size: 0;
    position: absolute;
    left: 50%;
    bottom: 0;
    line-height: 48px;
    z-index: 4; }
    .slider .slick-arrow::before {
      content: '';
      color: #fff;
      font-size: 12px;
      line-height: 36px;
      position: absolute;
      top: 38%;
      left: 38%; }
    .slider .slick-arrow.slick-prev {
      -webkit-transform: translate(-56px, 4rem);
          -ms-transform: translate(-56px, 4rem);
              transform: translate(-56px, 4rem); }
      .slider .slick-arrow.slick-prev::before {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 32'%3E%3Cpath fill='%23ffffff' d='M0.014 15.713c0.082-0.67 0.382-1.259 0.827-1.703v0l12.522-12.875c0.607-0.643 1.443-1.064 2.377-1.132l0.012-0.001c0.101-0.010 0.219-0.015 0.338-0.015 0.846 0 1.628 0.277 2.259 0.744l-0.010-0.007c0.677 0.494 1.132 1.256 1.209 2.127l0.001 0.011c0.003 0.050 0.005 0.108 0.005 0.167 0 0.823-0.349 1.565-0.907 2.086l-0.002 0.002-10.588 10.883 10.588 10.871c0.56 0.522 0.909 1.264 0.909 2.088 0 0.059-0.002 0.117-0.005 0.175l0-0.008c-0.077 0.882-0.533 1.645-1.201 2.133l-0.008 0.006c-0.625 0.468-1.412 0.749-2.266 0.749-0.113 0-0.225-0.005-0.336-0.015l0.014 0.001c-0.946-0.069-1.782-0.49-2.388-1.131l-0.002-0.002-12.522-12.878c-0.518-0.513-0.839-1.225-0.839-2.011 0-0.093 0.004-0.185 0.013-0.276l-0.001 0.012z'%3E%3C/path%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 1em;
        width: 1em; }
    .slider .slick-arrow.slick-next {
      -webkit-transform: translate(16px, 4rem);
          -ms-transform: translate(16px, 4rem);
              transform: translate(16px, 4rem); }
      .slider .slick-arrow.slick-next::before {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23ffffff' d='M26.581 15.652c-0.081-0.667-0.38-1.253-0.821-1.696l0 0-12.476-12.8c-0.605-0.642-1.439-1.062-2.37-1.13l-0.012-0.001c-0.104-0.010-0.225-0.016-0.348-0.016-0.838 0-1.613 0.273-2.24 0.734l0.010-0.007c-0.676 0.49-1.132 1.248-1.212 2.115l-0.001 0.011c-0.003 0.049-0.005 0.106-0.005 0.164 0 0.82 0.347 1.559 0.903 2.079l0.002 0.002 10.556 10.834-10.549 10.83c-0.561 0.521-0.911 1.263-0.911 2.086 0 0.055 0.002 0.11 0.005 0.165l-0-0.008c0.076 0.88 0.53 1.641 1.197 2.128l0.008 0.006c0.617 0.454 1.392 0.727 2.23 0.727 0.122 0 0.244-0.006 0.363-0.017l-0.015 0.001c0.943-0.068 1.777-0.489 2.381-1.129l0.002-0.002 12.476-12.8c0.515-0.511 0.834-1.219 0.834-2.002 0-0.094-0.005-0.187-0.014-0.278l0.001 0.012z'%3E%3C/path%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 1em;
        width: 1em; }
    .slider .slick-arrow.slick-disabled {
      opacity: .5; }

/**
 * ==================== @ GENERIC / PAGES
 */
:not(.home) .page-header {
  margin-bottom: 2rem; }
  :not(.home) .page-header .page-header__inner {
    background-color: #4CB8AD;
    color: #ffffff;
    min-height: 166px;
    padding: 2rem 1rem 0 1rem;
    max-width: 100%; }
  :not(.home) .page-header .page-header__title {
    font-size: 1.75em;
    margin-bottom: 0;
    padding-bottom: 1.5rem; }
  :not(.home) .page-header .page-header__caption p {
    margin-bottom: 0;
    padding-bottom: 2rem; }
  :not(.home) .page-header .page-header__metas {
    padding-bottom: 2rem; }

/**
 * ==================== @ HOME
 */
.home .page-header .grid-layout {
  padding: 0;
  grid-gap: 2rem; }

.home .page-header h2, .home .page-header p {
  color: #5D2C7B; }

.home .page-header h2 {
  margin: 0; }

.home .pg__section .bloc-head .grid-item:first-child {
  grid-column: 1 / -1;
  grid-row: 2 / auto; }

.home .pg__section .bloc-head .grid-item:nth-of-type(2) {
  grid-column: 4 / span 8;
  grid-row: 1 / auto; }

.home .pg__section .bloc-head .grid-item:last-child {
  grid-column: 1 / -1;
  grid-row: 3 / auto; }

.home .pg__section.news-slides h2 {
  color: #4CB8AD; }

/**
 * ==================== @ ALL PAGES
 */
.related-title {
  text-align: left !important; }
  .related-title h2 {
    color: #4CB8AD;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }

/* Custom title
----------------*/
.mega-title {
  margin: 2rem auto;
  text-align: center;
  padding: 0 2rem; }
  .mega-title.bsh-actions {
    color: #5D2C7B;
    font-size: 1.5em;
    font-family: "arvo", Helvetica, Arial, sans-serif;
    position: relative;
    font-weight: 700; }
    .mega-title.bsh-actions::before {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 32'%3E%3Cpath fill='%235D2C7B' d='M31.394 1.613c-0.001 0.764-0.533 1.403-1.247 1.568l-0.011 0.002c-13.611 3.153-24.082 13.819-26.905 27.296l-0.040 0.231c-0.155 0.741-0.803 1.29-1.579 1.29-0.89 0-1.611-0.721-1.611-1.611 0-0.114 0.012-0.224 0.034-0.331l-0.002 0.010c3.124-14.954 14.547-26.59 29.134-29.978l0.261-0.051c0.107-0.025 0.229-0.039 0.354-0.039 0.89 0 1.611 0.721 1.611 1.611 0 0.001 0 0.001 0 0.002v-0z'%3E%3C/path%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 1em;
      width: 1em;
      left: 8px; }
    .mega-title.bsh-actions::after {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 32'%3E%3Cpath fill='%235D2C7B' d='M30.975 32.001c-0.779-0.001-1.43-0.543-1.598-1.271l-0.002-0.011c-3.214-13.874-14.086-24.548-27.824-27.425l-0.235-0.041c-0.756-0.158-1.315-0.818-1.315-1.609 0-0.907 0.735-1.642 1.642-1.642 0.116 0 0.229 0.012 0.338 0.035l-0.011-0.002c15.244 3.184 27.104 14.829 30.558 29.697l0.052 0.266c0.026 0.109 0.040 0.233 0.040 0.361 0 0.907-0.735 1.642-1.642 1.642-0.001 0-0.001 0-0.002 0h0z'%3E%3C/path%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 1em;
      width: 1em;
      right: 8px; }
    .mega-title.bsh-actions::before, .mega-title.bsh-actions::after {
      content: '';
      position: absolute;
      bottom: 6px;
      height: 32px;
      width: 32px; }

/* Filter Agenda/Articles
----------------------*/
.tags-filter__wrapper {
  max-width: 80em;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 1rem; }
  .tags-filter__wrapper div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
    .tags-filter__wrapper div:first-child {
      color: #5D2C7B;
      font-weight: 700;
      font-family: "arvo", Helvetica, Arial, sans-serif;
      font-size: 1.5em; }
  .tags-filter__wrapper .tags-filter a {
    background-color: #EEE9F1;
    border-radius: 24px;
    color: #5D2C7B;
    display: block;
    padding: .75rem 1.5rem;
    -webkit-transition: background-color 250ms, color 250ms;
    transition: background-color 250ms, color 250ms; }
  .tags-filter__wrapper .tags-filter.m--active a, .tags-filter__wrapper .tags-filter:hover a {
    background-color: #5D2C7B;
    color: #ffffff; }

.pg__section {
  margin: 1rem auto 0 auto;
  /* Section slider in Edito
	---------------------*/
  /* Section external link
	---------------------*/
  /* Section bloc vidéo
	---------------------*/
  /* Page form - contact
	---------------------*/ }
  .pg__section.img-wide {
    margin: 0; }
    .pg__section.img-wide figure {
      border-radius: 0; }
  .pg__section.actions {
    margin-top: 0;
    margin-bottom: 4rem;
    /* Specific for grid actions */ }
    .pg__section.actions .action-icon .svg-icon {
      font-size: 6em; }
    .pg__section.actions .action-title {
      font-size: 1.5em;
      font-weight: 700;
      margin: 0.5rem 0 1rem; }
    .pg__section.actions .action-text p {
      font-size: 1.25em; }
    .pg__section.actions .layout-two-cols {
      padding: 1rem; }
      .pg__section.actions .layout-two-cols .grid-item {
        padding-top: 2rem; }
        .pg__section.actions .layout-two-cols .grid-item.section-title {
          padding-top: 0; }
  .pg__section.infos {
    margin: 1rem auto 0 auto;
    /* Specific for blocs actions */ }
    .pg__section.infos .layout-two-cols .grid-item {
      padding: 1rem 1.5rem; }
    .pg__section.infos .wrapper-10-cols h2 {
      font-size: 1.875em; }
  .pg__section.slider-wrapper {
    background-color: #4CB8AD;
    padding: 3rem 0 6rem 0; }
  .pg__section.bloc-link {
    margin-top: 1rem; }
    .pg__section.bloc-link .grid-item {
      background: #4CB8AD;
      padding-right: 3rem;
      position: relative; }
      .pg__section.bloc-link .grid-item a {
        color: #ffffff;
        display: block;
        font-size: 1.25em;
        padding: 2rem;
        -webkit-transition: color 350ms;
        transition: color 350ms; }
        .pg__section.bloc-link .grid-item a:hover {
          color: #0E4741; }
      .pg__section.bloc-link .grid-item .svg-icon {
        color: #ffffff;
        font-size: 2.25em;
        position: absolute;
        right: 2rem;
        top: 2rem; }
  .pg__section.search_results .grid-layout {
    padding-top: .5rem; }
  .pg__section.search_results .result_thema {
    color: #5D2C7B;
    font-weight: 700; }
  .pg__section.search_results .section-title {
    font-size: 1.5rem; }
    .pg__section.search_results .section-title a:hover {
      text-decoration: underline; }
  .pg__section.search_results .excerpt {
    font-size: 1rem; }
    .pg__section.search_results .excerpt p {
      font-size: 1rem;
      margin-bottom: .1rem; }
  .pg__section.video {
    margin-top: 0; }
    .pg__section.video .grid-item {
      position: relative; }
      .pg__section.video .grid-item i {
        color: #5D2C7B;
        font-size: 3.5em;
        position: absolute;
        left: 50%;
        top: 10%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        -webkit-transition: color 200ms;
        transition: color 200ms;
        z-index: 2; }
      .pg__section.video .grid-item .js-video:hover .svg-icon {
        color: #0E4741; }
  .pg__section.contact .grid-layout {
    -webkit-box-align: start;
    -webkit-align-items: start;
        -ms-flex-align: start;
            align-items: start; }
  .pg__section.contact .grid-item {
    position: relative; }
  .pg__section.contact .grid-item-inner {
    background: #E3EDF0 url("/assets/images/layout/clouds.png") no-repeat center;
    background-size: contain;
    border-radius: 0 4rem 4rem 0;
    padding: 6rem 0 18rem 0; }
  .pg__section.contact .contact-infos {
    color: #0E4741;
    max-width: 300px;
    margin: 0 auto; }
    .pg__section.contact .contact-infos h2 {
      font-size: 1.875em;
      margin-top: 0;
      margin-bottom: 1.5rem; }
  .pg__section.contact figure {
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }

.pg__section.error {
  background: url("/assets/images/layout/error-404.webp") no-repeat right bottom;
  background-size: contain;
  min-height: 620px;
  margin-top: -6rem; }
  .no-webp .pg__section.error, .no-js .pg__section.error {
    background-image: url("/assets/images/layout/error-404.png"); }
  .pg__section.error .grid-layout {
    padding-top: 0; }
  .pg__section.error .grid-item {
    padding-top: 8rem; }

.error .bloc-contact-team {
  margin-top: 0; }

/* Bloc contact bottom
---------------------*/
.bloc-contact-team {
  margin: 0; }
  .bloc-contact-team .grid-wrapper {
    padding: 0; }
  .bloc-contact-team .grid-layout {
    padding: 2rem 0; }
    .bloc-contact-team .grid-layout .grid-item {
      padding: 1rem !important; }

/* Bloc newsletter bottom
------------------------*/
.bloc-newsletter {
  background: url("/assets/images/layout/enveloppe.png");
  margin: 0;
  padding: 2rem 0; }
  .bloc-newsletter .grid-layout {
    padding: 2rem 0; }
  .bloc-newsletter .grid-item {
    padding: 0; }

@media screen and (max-width: 1023px) {
  .bloc-contact-team {
    background-color: transparent; }
    .bloc-contact-team .grid-wrapper {
      margin: 0; }
    .bloc-contact-team .grid-item {
      padding: 0;
      /* Picture */ }
      .bloc-contact-team .grid-item:first-child {
        background-color: #5D2C7B;
        grid-row: 2 / auto;
        padding: 2rem;
        margin-top: -2.2rem; }
        .bloc-contact-team .grid-item:first-child h2, .bloc-contact-team .grid-item:first-child p {
          color: #ffffff; }
      .bloc-contact-team .grid-item:last-child {
        grid-row: 1 / auto;
        text-align: center;
        justify-items: center; }
        .bloc-contact-team .grid-item:last-child img {
          display: inline-block;
          max-width: 75% !important; } }

/* === 1024px === */
@media screen and (min-width: 64em) {
  :not(.home) .page-header {
    margin-bottom: 2rem; }
    :not(.home) .page-header .page-header__inner {
      border-radius: 138px 0 0 42px;
      min-height: 166px;
      padding: 2rem -webkit-calc(50vw - 40em) 0 120px;
      padding: 2rem calc(50vw - 40em) 0 120px;
      max-width: 75%;
      margin: 0 0 0 auto; }
    :not(.home) .page-header .page-header__title {
      font-size: 3.125em; }
  /* Custom title
	----------------*/
  .mega-title {
    width: -webkit-max-content;
    width: max-content; }
    .mega-title.bsh-actions {
      font-size: 2.5em; }
      .mega-title.bsh-actions::before {
        left: -64px; }
      .mega-title.bsh-actions::after {
        right: -64px; }
      .mega-title.bsh-actions::before, .mega-title.bsh-actions::after {
        bottom: -24px;
        height: 52px;
        width: 52px; }
  /* Custom title
	----------------*/
  .home .page-header {
    grid-gap: 8rem;
    margin-top: -2rem; }
    .home .page-header .grid-item:first-child {
      padding-right: 4rem; }
    .home .page-header .grid-item:last-child {
      padding-bottom: 8rem;
      padding-right: 4rem; }
  .home .pg__section .bloc-head .grid-item:first-child {
    grid-column: 1 / span 7;
    grid-row: 1 / auto;
    padding-top: 4rem; }
  .home .pg__section .bloc-head .grid-item:nth-of-type(2) {
    grid-column: 8 / span 5;
    grid-row: 1 / span 2; }
  .home .pg__section .bloc-head .grid-item:last-child {
    grid-column: 1 / span 7;
    grid-row: 2 / auto; }
  .pg__section {
    margin: 3rem auto 0 auto;
    /* Section bloc vidéo
    ---------------------*/ }
    .pg__section.actions {
      /* Specific for grid actions */ }
      .pg__section.actions .layout-two-cols {
        padding: 2rem 6rem; }
        .pg__section.actions .layout-two-cols .grid-item {
          padding-top: 2rem; }
    .pg__section.bloc-link .grid-item a {
      font-size: 1.875em; }
      .pg__section.bloc-link .grid-item a:hover {
        color: #0E4741; }
    .pg__section.bloc-link .grid-item .svg-icon {
      font-size: 3em; }
    .pg__section.infos .layout-two-cols .grid-item {
      padding: 2rem 4rem; }
    .pg__section.bloc-contact-team {
      background-color: #5D2C7B; }
      .pg__section.bloc-contact-team .grid-layout {
        padding: 2rem 0; }
      .pg__section.bloc-contact-team .grid-item {
        /* Picture */ }
        .pg__section.bloc-contact-team .grid-item:first-child {
          padding-top: 2rem; }
        .pg__section.bloc-contact-team .grid-item:last-child img {
          max-width: 80% !important; }
    .pg__section.video .grid-item i {
      font-size: 4.5em;
      top: 25%; } }

/**
 * ==================== @ EDITO
 */
.edito {
  counter-reset: summary 0; }
  .edito .summary {
    background-color: #EEF5F4;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: .2rem 1rem 1rem 1rem; }
    .edito .summary li {
      font-size: 1.25em;
      margin-bottom: .5rem;
      margin-left: 36px;
      position: relative; }
      .edito .summary li::before {
        counter-increment: summary;
        content: counter(summary, decimal-leading-zero);
        color: #4CB8AD;
        font-size: 24px;
        font-weight: 700;
        font-family: "arvo", Helvetica, Arial, sans-serif;
        margin-right: .5rem;
        min-width: 30px;
        position: absolute;
        left: -36px;
        top: -2px;
        text-align: right; }
      .edito .summary li a {
        display: inline-block;
        position: relative; }
        .edito .summary li a::after {
          content: '';
          height: 1px;
          background-color: #4CB8AD;
          width: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          -webkit-transition: width 250ms;
          transition: width 250ms; }

@media all and (min-width: 48em) {
  .edito-section .edito-wrapper-slider {
    width: -webkit-calc(100% + 3rem);
    width: calc(100% + 3rem); }
  .edito .summary {
    padding: .2rem 4rem 1rem 4rem; }
    .edito .summary li a:hover::after {
      width: 100%; } }

/**
 * ==================== @ ARTICLES & TEASER
 */
.news-teaser {
  padding: 0 !important; }
  .news-teaser figure {
    border: 4px solid #4CB8AD;
    position: relative; }
    .news-teaser figure figcaption {
      background-color: #4CB8AD;
      border-radius: 0 10px 10px 0;
      color: #ffffff;
      font-size: 1.125em;
      padding: .5rem 1rem;
      position: absolute;
      left: 0;
      bottom: 1rem;
      z-index: 2; }
    .news-teaser figure img {
      -webkit-transition: -webkit-transform 250ms;
      transition: -webkit-transform 250ms;
      transition: transform 250ms;
      transition: transform 250ms, -webkit-transform 250ms; }
  .news-teaser .news-teaser__caption {
    padding-top: 1rem; }
  .news-teaser time {
    font-size: 1.125em; }
  .news-teaser h3 {
    font-size: 1.3125em; }
  .news-teaser p {
    font-size: 1.25em; }
  .news-teaser h3 {
    color: #4CB8AD;
    font-family: "roboto", Helvetica, Arial, sans-serif; }

.articles {
  /* Related
	----------------*/ }
  .articles .news-related {
    padding: 2rem; }

.article .page-header__metas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.article .page-header__tag {
  background-color: #EEE9F1;
  border-radius: 24px;
  color: #5D2C7B;
  font-size: 1.125em;
  font-weight: 700;
  margin-right: 1rem;
  padding: .75rem 1.5rem; }

.article .page-header__date {
  font-size: 1.25em; }

@media all and (min-width: 48em) {
  .news-teaser time {
    font-size: 1.125em; }
  .news-teaser h4 {
    font-size: 1.375em; }
  .news-teaser p {
    font-size: 1.3125em; }
  .news-teaser h3 {
    color: #4CB8AD;
    font-family: "roboto", Helvetica, Arial, sans-serif; }
  .news-teaser figure:hover img {
    -webkit-transform: scale(1.02, 1.02);
        -ms-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02); } }

/**
 * ==================== @ AGENDA & EVENEMENTS
 */
.events-slides .section-title h2 {
  color: #5D2C7B; }

.events-slides .grid-item {
  padding: 0; }

.events-slides .event-slide__caption {
  background-color: #EEE9F1;
  border-radius: 1.5rem 1.5rem 0 0;
  color: #5D2C7B;
  min-height: 200px;
  padding: 2rem 1rem 3rem 1rem;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 250ms;
  transition: background-color 250ms; }
  .events-slides .event-slide__caption h3 {
    color: #5D2C7B; }
  .events-slides .event-slide__caption span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1rem;
    text-align: center; }

.events-slides .event-type {
  background-color: #5D2C7B;
  border-radius: 0 0 1.5rem 1.5rem;
  color: #ffffff;
  font-size: 1.25em;
  padding: 1rem 0;
  text-align: center; }
  .events-slides .event-type .svg-icon {
    margin-right: .25rem;
    vertical-align: -2px; }

.section-event .grid-item h2, .section-event .grid-item h3 {
  color: #4CB8AD; }

.section-event .grid-item a, .section-event .grid-item p {
  color: #0E4741; }

.section-event .grid-item a {
  font-weight: 700; }

/* Page Event
----------------*/
.event .page-header__metas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.event .page-header__tag {
  background-color: #EEE9F1;
  border-radius: 24px;
  color: #5D2C7B;
  font-size: 1.125em;
  font-weight: 700;
  margin-right: 1rem;
  padding: .75rem 1.5rem; }

.event .page-header__infos {
  font-size: 1.25em; }
  .event .page-header__infos span {
    display: inline-block;
    margin-right: 1rem; }
  .event .page-header__infos .header-event-author::before {
    content: '\2022';
    font-size: 2em;
    line-height: 0;
    margin-right: 8px;
    vertical-align: -8px; }

@media all and (min-width: 48em) {
  .events-slides .event-slide__caption:hover {
    background-color: #ded5e4; }
  .events-slides .event-slide__caption {
    height: 250px;
    padding: 3rem 2rem; } }

.page-map-filter__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 1rem;
  max-width: 80em;
  margin: 2rem auto 0 auto;
  padding-left: 1rem;
  padding-right: 1rem; }
  .page-map-filter__wrapper div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
  .page-map-filter__wrapper .label, .page-map-filter__wrapper .result {
    color: #5D2C7B; }
  .page-map-filter__wrapper .label {
    font-size: 1.875em; }
  .page-map-filter__wrapper .result {
    font-size: 1.125em; }

.page-map {
  margin: 0;
  padding: 0; }
  .page-map .grid-wrapper {
    padding-top: 0; }
  .page-map .blocks-list {
    margin-top: 2rem;
    opacity: 1;
    -webkit-transition: opacity 350ms;
    transition: opacity 350ms; }
    .page-map .blocks-list.hide {
      opacity: 0; }
  .page-map .list-wrapper {
    min-height: 50vh; }
    .page-map .list-wrapper .list-scroll {
      height: -webkit-calc(100vh - 220px);
      height: calc(100vh - 220px);
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      position: relative; }
      .page-map .list-wrapper .list-scroll::-webkit-scrollbar {
        display: none; }
  .page-map .map-wrapper {
    background-color: #EEF5F4;
    border-bottom-left-radius: 4rem; }
    .page-map .map-wrapper .js-map-view {
      min-height: 40vh; }

/* === 1024px === */
@media all and (min-width: 64em) {
  .page-map-filter__outer {
    background-color: #5D2C7B; }
  .page-map-filter__wrapper {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    .page-map-filter__wrapper div {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
    .page-map-filter__wrapper .label {
      padding-right: 1.5rem; }
    .page-map-filter__wrapper .label, .page-map-filter__wrapper .result {
      color: #ffffff; }
    .page-map-filter__wrapper .dropdown {
      border-right: 1px solid #ffffff; }
      .page-map-filter__wrapper .dropdown.m--first {
        border-left: 1px solid #ffffff; }
    .page-map-filter__wrapper .result {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      text-align: right; }
      .page-map-filter__wrapper .result .result-edge {
        -webkit-transform: translateX(-webkit-calc((50vw - 640px) - 1rem));
            -ms-transform: translateX(calc((50vw - 640px) - 1rem));
                transform: translateX(calc((50vw - 640px) - 1rem)); }
  .page-map {
    margin-bottom: 4rem; }
    .page-map .map-wrapper .js-map-view {
      height: -webkit-calc(100vh - 220px);
      height: calc(100vh - 220px); } }

.ressources-filter {
  color: #5D2C7B;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 1rem;
  max-width: 80em;
  margin: 2rem auto 0 auto;
  padding-left: 1rem;
  padding-right: 1rem; }
  .ressources-filter div {
    background-color: rgba(93, 44, 123, 0.09);
    color: #5D2C7B;
    font-size: 1.25em;
    padding: 1rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%; }
    .ressources-filter div:first-child {
      background-color: transparent;
      font-size: 1.875em; }

.ressources {
  margin: 0;
  padding: 0; }
  .ressources .pg__section {
    margin-top: 1rem; }
  .ressources .grid-wrapper {
    padding-top: 0; }
  .ressources .section-title {
    color: #4CB8AD;
    font-size: 1.625em; }
  .ressources .metas {
    line-height: .7; }
  .ressources .metas li, .ressources .author {
    color: #5D2C7B;
    font-size: 1.125em; }
  .ressources .metas li {
    display: inline-block;
    margin-right: .5rem; }
    .ressources .metas li:not(:first-child)::before {
      content: '\2022';
      font-size: 2em;
      margin-right: 6px;
      vertical-align: -7px; }
  .ressources .author {
    margin-top: .5rem;
    margin-bottom: 1rem; }

@media all and (min-width: 64em) {
  .ressources-filter {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    .ressources-filter div {
      border-right: 1px solid #ffffff;
      padding: 1rem 2rem;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%; }
      .ressources-filter div:first-child {
        padding-left: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
            -ms-flex: 1;
                flex: 1; } }

/**
 * ==================== @ Really ?!
 */
.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.is-hidden {
  display: none !important;
  overflow: hidden !important; }
