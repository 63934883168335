/**
 * ==================== @ POPUPS
 */

.mfp-bg {
	background-color: #000;
	opacity: .8;
}

.mfp-container {
	.mfp-content {
		max-width: 800px;
	}
}

.mfp-iframe {
	//border-radius: $radius;
}

.popup {
	background-color: #fff;
	//border-radius: $radius;
	margin: 30px 0;
	padding: 8%;
	position: relative;
}
  