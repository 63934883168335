/**
 * ==================== @ GENERIC / PAGES
 */

:not(.home) .page-header {
	margin-bottom: 2rem;

	.page-header__inner {
		background-color: $c-green;
		color: $c-white;
		min-height: 166px;
		padding: 2rem 1rem 0 1rem;
		max-width: 100%;
	}

	.page-header__title {
		font-size: em(28);
		margin-bottom: 0;
		padding-bottom: 1.5rem;
	}

	.page-header__caption p {
		margin-bottom: 0;
		padding-bottom: 2rem;
	}

	.page-header__metas {
		padding-bottom: 2rem;
	}
}

/**
 * ==================== @ HOME
 */

.home {
	.page-header {

		.grid-layout {
			padding: 0;
			grid-gap: 2rem;
		}

		h2, p {
			color: $c-violet;
		}

		h2 {
			margin: 0;
		}
	}

	.pg__section {

		.bloc-head {

			.grid-item:first-child {
				grid-column: 1 / -1;
				grid-row: 2 / auto;
			}

			.grid-item:nth-of-type(2)  {
				grid-column: 4 / span 8;
				grid-row: 1 / auto;
			}

			.grid-item:last-child {
				grid-column: 1 / -1;
				grid-row: 3 / auto;
			}
		}

		&.news-slides {

			h2 {
				color: $c-green;
			}
		}
	}
}

/**
 * ==================== @ ALL PAGES
 */

.related-title {
	text-align: left !important;

	h2 {
		color: $c-green;
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
}

/* Custom title
----------------*/
.mega-title {
	margin: 2rem auto;
	text-align: center;
	padding: 0 2rem;

	&.bsh-actions {
		color: $c-violet;
		font-size: em(24);
		font-family: $font-alt;
		position: relative;
		font-weight: $fw-bold;

		&::before {
			@include background-svg($svg-arc-l, $c-violet);
			left: 8px;
		}

		&::after {
			@include background-svg($svg-arc-r, $c-violet);
			right: 8px;
		}

		&::before, &::after {
			content: '';
			position: absolute;
			bottom: 6px;
			height: 32px;
			width: 32px;
		}
	}
}


/* Filter Agenda/Articles
----------------------*/
.tags-filter__wrapper {
	max-width: 80em;
	margin: 0 auto;
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;

	div {
		flex: 0 1 auto;

		&:first-child {
			color: $c-violet;
			font-weight: $fw-bold;
			font-family: $font-alt;
			font-size: em(24);
		}
	}

	.tags-filter {

		a {
			background-color: $c-rose;
			border-radius: 24px;
			color: $c-violet;
			display: block;
			padding: .75rem 1.5rem;
			transition: background-color 250ms, color 250ms;
		}

		&.m--active a, &:hover a {
			background-color: $c-violet;
			color: $c-white;
		}
	}
}


.pg__section {
	margin: 1rem auto 0 auto;

	&.img-wide {
		margin: 0;

		figure {
			border-radius: 0;
		}
	}

	&.actions {
		margin-top: 0;
		margin-bottom: 4rem;

		.action-icon {
			.svg-icon {
				font-size: em(96);
			}
		}

		.action-title {
			font-size: em(24);
			font-weight: $fw-bold;
			margin: 0.5rem 0 1rem;
		}

		.action-text p {
			font-size: em(20, 16);
		}

		/* Specific for grid actions */
		.layout-two-cols {
			padding: 1rem;

			.grid-item {
				padding-top: 2rem;

				&.section-title {
					padding-top: 0;
				}
			}
		}
	}

	&.infos {
		margin: 1rem auto 0 auto;
		/* Specific for blocs actions */
		.layout-two-cols {

			.grid-item {
				padding: 1rem 1.5rem;
			}
		}

		.wrapper-10-cols {

			h2 {
				font-size: em(30);
			}
		}
	}

	/* Section slider in Edito
	---------------------*/
	&.slider-wrapper {
		background-color: $c-green;
		padding: 3rem 0 6rem 0;
	}

	/* Section external link
	---------------------*/
	&.bloc-link {
		margin-top: 1rem;

		.grid-item {
			background: $c-green;
			padding-right: 3rem;
			position: relative;

			a {
				color: $c-white;
				display: block;
				font-size: em(20);
				padding: 2rem;
				transition: color 350ms;

				&:hover {
					color: $c-green-txt;
				}
			}

			.svg-icon {
				color: $c-white;
				font-size: em(36);
				position: absolute;
				right: 2rem;
				top: 2rem;
			}
		}
	}
	
	&.search_results {
		
		.grid-layout {
			padding-top: .5rem;
		}
		
		.result_thema {
			color: $c-violet;
			font-weight: $fw-bold;
		}
		
		.section-title {
			font-size: rem(24);
			
			a:hover {
				text-decoration: underline;
			}
		}
		
		.excerpt {
			font-size: rem(16);
			
			p {
				font-size: rem(16);
				margin-bottom: .1rem;
			}
			
		}
	}

	/* Section bloc vidéo
	---------------------*/
	&.video {
		margin-top: 0;

		.grid-item {
			position: relative;

			i{
				color: $c-violet;
				font-size: em(56);
				position: absolute;
				left: 50%;
				top: 10%;
				transform: translateX(-50%);
				transition: color 200ms;
				z-index: 2;
			}

			.js-video:hover .svg-icon {
				color: $c-green-txt;
			}
		}
	}

	/* Page form - contact
	---------------------*/
	&.contact {

		.grid-layout {
			align-items: start;
		}

		.grid-item {
			position: relative;
		}

		.grid-item-inner {
			background: $c-greyblue url(path('layout/clouds.png', $img-path)) no-repeat center;
			background-size: contain;
			border-radius: 0 $radius-60 $radius-60 0;
			padding: 6rem 0 18rem 0;
		}

		.contact-infos {
			color: $c-green-txt;
			max-width: 300px;
			margin: 0 auto;

			h2 {
				font-size: em(30);
				margin-top: 0;
				margin-bottom: 1.5rem;
			}
		}

		figure {
			position: absolute;
			left: 50%;
			top: 60%;
			transform: translateX(-50%);
		}
	}
}

.pg__section.error {
	background: url(path('layout/error-404.webp', $img-path)) no-repeat right bottom;
	background-size: contain;
	min-height: 620px;
	margin-top: -6rem;

	.no-webp &, .no-js & {
		background-image: url(path('layout/error-404.png', $img-path));
	}

	.grid-layout {
		padding-top: 0;
	}

	.grid-item {
		padding-top: 8rem;
	}
}

.error .bloc-contact-team {
	margin-top: 0;
}

/* Bloc contact bottom
---------------------*/
.bloc-contact-team {
	margin: 0;

	.grid-wrapper {
		padding: 0;
	}

	.grid-layout {
		padding: 2rem 0;

		.grid-item {
			padding: 1rem !important;
		}
	}
}

/* Bloc newsletter bottom
------------------------*/
.bloc-newsletter {
	background: url(path('layout/enveloppe.png', $img-path));
	margin: 0;
	padding: 2rem 0;

	.grid-layout {
		padding: 2rem 0;
	}

	.grid-item {
		padding: 0;
	}
}

@media screen and (max-width:1023px) {

	.bloc-contact-team {
		background-color: transparent;

		.grid-wrapper {
			margin: 0;
		}

		.grid-item {
			padding: 0;

			&:first-child {
				background-color: $c-violet;
				grid-row: 2 / auto;
				padding: 2rem;
				margin-top: -2.2rem;

				h2, p {
					color: $c-white;
				}
			}

			/* Picture */
			&:last-child {
				grid-row: 1 / auto;
				text-align: center;
				justify-items: center;

				img {
					display: inline-block;
					max-width: 75% !important;
				}
			}
		}
	}
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {

	:not(.home) .page-header {
		margin-bottom: 2rem;

		.page-header__inner {
			border-radius: 138px 0 0 42px;
			min-height: 166px;
			padding: 2rem calc(50vw - 40em) 0 120px;
			max-width: 75%;
			margin: 0 0 0 auto;
		}

		.page-header__title {
			font-size: em(50);
		}

		.page-header__caption {
		}
	}

	/* Custom title
	----------------*/
	.mega-title {
		width: max-content;

		&.bsh-actions {
			font-size: em(40);

			&::before {
				left: -64px;
			}

			&::after {
				right: -64px;
			}

			&::before, &::after {
				bottom: -24px;
				height: 52px;
				width: 52px;
			}
		}
	}

	/* Custom title
	----------------*/
	.home {
		.page-header {
			grid-gap: 8rem;
			margin-top: -2rem;

			.grid-item:first-child {
				padding-right: 4rem;
			}
			.grid-item:last-child {
				padding-bottom: 8rem;
				padding-right: 4rem;
			}
		}

		.pg__section {

			.bloc-head {

				.grid-item:first-child {
					grid-column: 1 / span 7;
					grid-row: 1 / auto;
					padding-top: 4rem;
				}

				.grid-item:nth-of-type(2)  {
					grid-column: 8 / span 5;
					grid-row: 1 / span 2;
				}

				.grid-item:last-child {
					grid-column: 1 / span 7;
					grid-row: 2 / auto;
				}
			}
		}
	}

	.pg__section {
		margin: 3rem auto 0 auto;

		&.actions {

			/* Specific for grid actions */
			.layout-two-cols {
				padding: 2rem 6rem;

				.grid-item {
					padding-top: 2rem;
				}
			}
		}

		&.bloc-link {

			.grid-item {
				a {
					font-size: em(30);

					&:hover {
						color: $c-green-txt;
					}
				}

				.svg-icon {
					font-size: em(48);
				}
			}
		}

		&.infos {
			.layout-two-cols {

				.grid-item {
					padding: 2rem 4rem;
				}
			}
		}

		&.bloc-contact-team {
			background-color: $c-violet;

			.grid-layout {
				padding: 2rem 0;
			}

			.grid-item {
				&:first-child {
					padding-top: 2rem;
				}

				/* Picture */
				&:last-child {

					img {
						max-width: 80% !important;
					}
				}
			}
		}

    /* Section bloc vidéo
    ---------------------*/
    &.video {
  
      .grid-item {
  
        i{
          font-size: em(72);
          top: 25%;
        }
      }
    }
	}
}
