/**
 * ==================== @ BLOCS
*/

.blocks-list {
	max-width: 100%;
	padding-bottom: calc(100vh - 340px);

	.card {
		max-width: 100%;
		border-bottom: 1px solid $c-black;
		min-height: 110px;
		margin-bottom: 1rem;
		position: relative;
		overflow: hidden;
		word-break: break-word;

		&.m--structure {
			min-height: 112px;
		}

		.card-name {
			display: flex;
			flex-direction: column;
			width: 100%;
			background-color: $c-white;
			cursor: pointer;
			font-size: em(21);
			font-family: $font-alt;
			font-weight: $fw-bold;
			text-align: left;
			padding-right: 2.5rem;
			position: relative;
			z-index: 2;

			&:focus {
				outline: 1px solid $c-greydark;
				outline-offset: -1px;
			}

			.speciality {
				color: $c-violet;
				font-family: $font-stack;
				display: block;
				font-size: em(20,24);
			}

			&::before, &::after {
				content: '';
				height: 4px;
				width: 40px;
				background-color: $c-violet;
				position: absolute;
				top: 1rem;
				right: 0;
				transition: transform 350ms;
			}

			&::before {
				transform: rotate(-90deg);
			}

			&::after {
				transform: rotate(-180deg);
			}
		}

		.card-metas {
			display: none;
			max-width: 100%;
			position: relative;
			z-index: 1;

			> div {
				font-size: em(20);
				margin-bottom: .5rem;

				p {
					font-size: em(20, 21);
					line-height: 1.1;
					margin-top: .1rem;
					margin-bottom: .2rem;
				}

				strong {
					color: $c-violet;
				}
			}

			.contact {
				line-height: 1.3;
				max-width: 100%;
				margin-bottom: 2rem;
				position: relative;

				a, span, p {
					display: inline-block;
					font-size: em(20, 21);
					font-weight: $fw-bold;
				}

				.btn.m--rdv {
					display: inline-block;
					padding: .5rem 1rem;
					position: absolute;
					top: -48px;
					right: 0;
				}

				.span {
					max-width: 90%;
					overflow-wrap: anywhere;
				}

				a:hover {
					text-decoration: underline;
				}
			}
		}

		.adress {
			font-size: em(20, 21);
			margin-bottom: .85rem;
			position: relative;

			picture {
				position: absolute;
				right: 0;
				top: 0;
				max-width: 110px;
			}
		}

		.mission {
			font-size: em(20, 21);
			padding-bottom: .85rem;
		}


		&.active {

			.card-name::before {
				transform: rotate(0);
			}

			.card-name::after {
				transform: rotate(180deg);
			}

			.card-metas {
				display: block;
			}
		}
	}
}

/* Bloc logo in flexbox / 7 Cols
-------------------------------*/

.grid-logos {
	padding: 1rem 0;

	.grid-item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-evenly;
		gap: 2rem;
	}

	.bloc-logo {
		flex: 0 0 25%;
		text-align: center;

		img {
			max-height: 84px;
		}
	}
}

/* Bloc download
----------------*/

.grid-item {

	&.download {
		position: relative;

		h2 {
			padding-right: 3.1rem;
			word-break: break-all;
		}

		.icn-download {
			position: absolute;
			right: 3rem;
			top: 2.5rem;

			.svg-icon {
				font-size: em(48);
			}
		}
	}
}

@media all and (min-width : $bp-m) {

	.blocks-list {

		.card {
			min-height: 84px;

			&.m--structure {
				min-height: 112px;
			}
		}
	}

	/* Bloc logo
	------------*/

	.grid-logos {

		.bloc-logo {
			flex: 0 0 13.33%;

			img {
				max-height: 140px;
			}
		}
	}
}
