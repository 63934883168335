/**
 * ==================== @ MENU NAV
 */
.nav {
	display: none;
	background-color: $c-violet;
	padding-top: 4rem;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 5rem;
	width: 100vw;
	will-change: transform;
	z-index: 99;

	@keyframes slideIn {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
  }

  @keyframes slideOut {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
  }

	@keyframes fadeIn {
		from { transform: translateX(200%); opacity: 0; }
		to { transform: translateX(0); opacity: 1; }
	}

	@keyframes fadeOut {
		from { transform: translateX(0); opacity: 1; }
		to { transform: translateX(200%); opacity: 0; }
	}

	&[aria-hidden="false"] {
		animation: slideIn 360ms ease-in-out;
	}

	&[aria-hidden="true"] {
		animation: slideOut 360ms ease-in-out;
	}

	.btn-close {
		right: 2rem;
	}

	.btn-close, .submenu-back, .js-menu-close {
		color: $c-white;
		position: absolute;
		top: 38px;

		&:focus {
			outline: 1px solid #fff;
			outline-offset: 4px;
			border-radius: 2px;
		}

		span {
			display: inline-block;
			font-size: em(18);
			font-weight: 700;
			margin-right: .5rem;
			text-transform: uppercase;
		}

		.svg-icon {
			font-size: em(18);
			vertical-align: -2px;
		}
	}

	.submenu-back {
		display: none;
		left: 1rem;
		top: 6rem;
		z-index: 4;

		&[aria-hidden="false"] {
			animation: fadeIn 360ms ease-in-out;
		}

		&[aria-hidden="true"] {
			animation: fadeOut 360ms ease-in-out;
		}

		span {
			margin-left: .5rem;
		}

		&.active {
			display: block;
		}
	}

  .js-menu-close {
    display: none;

    .svg-icon {
      margin-right: .5rem;
    }
  }

	.nav-menu {
		padding: 1rem 1.5rem 1rem 1rem;
		text-align: right;

    &.m--bottom {
      padding-right: 2.5rem;
    }

		.menu-item {

			> :is(a, button) {
        padding: .5rem 1rem;
        border-radius: 99px;
        transition: color 250ms, background-color 250ms;

				&:focus {
					outline: 1px solid #fff;
					outline-offset: 2px;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&.has-children {

				> button {
					cursor: pointer;
					font-weight: normal;
				}
			}
		}

		a, button, span {
			display: block;
			width: 100%;
			color: $c-white;
			font-size: em(18);
			font-family: $font-alt;
			text-align: inherit;
		}

		span {
			font-weight: 700;
      padding-right: 1rem;
		}

		&.m--first {
			background-color: $c-violet;
			padding-top: 1rem;
			.nav-submenu {
				background-color: $c-violet;
			}
		}

		&.m--second {
			background-color: $c-violet;
			.nav-submenu {
				background-color: $c-violet;
			}
		}

		&.m--third {
			background-color: $c-violet;
			.nav-submenu {
				background-color: $c-violet;
			}
		}
	}

	.nav-submenu {
		display: none;
		padding: 8rem 2rem 1rem 1rem;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100vw;
		will-change: transform;
		z-index: 2;

		&[aria-hidden="false"] {
			animation: slideIn 360ms ease-in-out;
		}

		&[aria-hidden="true"] {
			animation: slideOut 360ms ease-in-out;
		}

		&.is-subnav-open {
			display: block;
		}
	}

	.is-nav-open & {
		display: block;
    min-height: 400px;
	}
}

@media all and (min-width : $bp-l) {

	.nav {
		border-bottom-left-radius: 120px;
		bottom: auto;
    top: 40px;
		padding-top: 4rem;
		overflow: hidden;
		width: 400px;

		.btn-close {
			right: 4rem;
		}

    .submenu-back {
      top: 2.5rem;
      left: 2rem;
    }

    .js-menu-close {
      display: block;
      top: 2.5rem;
      right: 3rem;
    }

		.nav-menu {
			padding: 2rem;

      .menu-item {

        > a:hover {
          background-color: $c-rose;
          color: $c-violet;
        }

        &.has-children {
  
          > button {

            &:hover {
              background-color: $c-rose;
              color: $c-violet;
            }
          }
        }
      }
		}

		.nav-submenu {
			padding: 5rem 2rem 1rem 1rem;
			width: 100%;
		}
	}
}

@media all and (min-width : 1790px) {

	.nav {
		width: 460px;
	}
}