/**
 * ==================== @ HEADER
 */

 .header {
	background-color: $c-white;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 100;

  &-top {
    background-color: $c-green-lite;

    &__inner {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1.2rem;
      margin: 0 auto;
      max-width: 80em;
      padding-right: .5rem;

      a {
        color: $c-green-dark;

        &:hover {
          text-decoration-line: underline;
          text-underline-offset: 4px;
        }
      }

      li:last-child a {
        font-weight: 700;

        .svg-icon {
          font-size: rem(10);
          vertical-align: 0;
        }
      }
    }
  }
	
	&-inner {
		height: 110px;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	
	&-right {
		height: 45px;
		display: flex;
		justify-content: flex-end;
		border: 0;
		border-radius: 999px;
		background-color: $c-violet;
		color: $c-white;
		padding-right: 1.5rem;
		padding-top: 0.25rem;
		padding-left: 0.25rem;
		padding-bottom: 0.25rem;
	}
	
	&-logo {
		position: absolute;
		left: 0;
		top: 26px;
	
		.website-logo {
			display: block;
	
			img {
				height: 54px;
				width: auto;
				transition: height 250ms, margin 250ms;
			}
		}
	}
	
	&-contact {
		margin-right: 1.5rem;
	}
	
	&-menu {
		padding-left: 15px;
	
		.menu-toggle {
			color: $c-white;
			display: flex;
			align-items:  center;
			font-size: em(24);
			line-height: 1;

			.svg-icon {
				vertical-align: middle;
			}

			.close {
				display: none;
			}
	
			&:focus {
				outline: 1px solid #fff;
				outline-offset: 4px;
				border-radius: 2px;
			}
	
			span {
				font-size: em(14, 24);
				font-weight: 700;
				margin-left: .5rem;
				text-transform: uppercase;
			}
		}

		.is-nav-open & {
			.burger {
				display: none;
			}
			.close {
				display: block;
			}
		}
	}
	
	&-top-menu {
		padding-right: 1.5rem;
		display: none;
		
		.top-menu {

			&__items {
				display: flex;
				align-items: center;
				gap: .5rem;
			}
		
			&__item, &__subitem {
				a, span {
					color: $c-violet;
					cursor: pointer;
					font-size: rem(16);
					display: block;
				}
			}
      
			&__item {
				position: relative;
				transition: background-color 250ms;
				z-index: 2;
        
				> a, > span {
					font-size: rem(18);
					font-weight: 700;
					padding: .5rem .75rem;
					position: relative;
				}
        
				> span {
				 padding-right: 2rem;
         
					 &::after {
						content: '';
						font-size: rem(12);
						position: absolute;
					  @include background-svg($svg-arrow, $c-violet);
						transform: rotate(90deg);
						right: 8px;
						top: 14px;
					}
				}
        
				&:hover {
					background-color: $c-rose;
          
					.top-menu__subitems {
						height: max-content;
						opacity: 1;
						transition: opacity 250ms;
					}
				}
			}
      
			&__subitems {
			  background-color: $c-rose;
				position: absolute;
				opacity: 0;
				width: max-content;
				height: 0;
				overflow: hidden;

        a {
          margin: .5rem;
          padding: .5rem;
          transition: background-color 250ms, color 250ms;
        }
			}
		}
	}
}

.header-search-and-menu {
	display: flex;
	align-items: center;
	position: relative;

	.header-search {
		background-color: $c-rose;
		display: flex;
		align-items: center;
		border-radius: 99px;
		margin-right: -3rem;
    max-height: 54px;
		position: relative;
		overflow: hidden;
		z-index: 2;

		.form-input[type=text] {
			padding: 0;
			margin: 13px 20px;
			max-width: 210px;
			transition: width 360ms;

			&:focus {
				outline: 1px solid $c-violet;
				outline-offset: 4px;
			}
		}

		.button-submit {
			height: 48px;
			width: 48px;
			background-color: #fff;
			border-radius: 50%;
			position: absolute;
			right: 4px;
			top: 3px;

			&:focus {
				outline: 1px solid $c-violet;
				outline-offset: 1px;
			}
		}

		.svg-icon {
			color: $c-violet;
			font-size: rem(28);
		}
	}

	.header-menu {
		background-color: $c-violet;
		color: #fff;
		display: flex;
		align-items: center;
		border-radius: 99px;
		padding: 14px 1.5rem 14px 4rem;

		span {
			font-size: rem(15);
			text-transform: uppercase;
		}
	}
}

@media all and (max-width : 560px) {
	
	.header {
		&-menu {
			span {
				display:none;
			}
		}
		
		&-contact {
			display: none;
		}
	}

	.header-search-and-menu {
	  z-index: 100;
	
		.header-search {
	
			.form-input[type=text] {
				width: 15px;
			}

			&.active, .is-nav-open & {
				.form-input[type=text] {
					width: 220px;
				}
			}
		}
	}
}

@media all and (min-width : 40em) {
	
  .header-accessibility {
    padding-right: 1.5rem;
  }
}

@media all and (min-width : $bp-m) {
	
	.header {
		
		&-inner {
			max-width: 80em;
			height: auto;
		}

    &-top-menu {

      .top-menu {
  
        &__subitems {

          a:hover {
            background-color: $c-violet;
            color: #fff;
          }
        }
      }
    }
	}
}

@media all and (min-width : $bp-l) {

	.header-logo {
    top: 0;

		.website-logo {

			img {
				height: 110px;
			}
		}

		.is-shrink & {

			.website-logo img {
				height: 72px;
				margin-top: .5rem;
			}
		}
	}
}

@media all and (min-width : $bp-1280) {

	.header {
	
		&-inner {
			margin: 0 auto;
		}
		
		&-top-menu {
			display: inherit;
		}
	}
}
