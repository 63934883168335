/**
 * ==================== @ Really ?!
 */

.is-semantic {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	display: block;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.is-hidden {
	display: none !important;
	overflow: hidden !important;
}