

/**
 * ==================== @ GENERIC FORMS
*/

.form-layout {
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.form-field {
	flex: 0 0 100%;
	position: relative;
	padding-bottom: 2rem;

	&.m--right {
		text-align: right;
	}

	&.m--1-3 {
		flex: 0 0 100%;
	}

	&.m--2-3 {
		flex: 0 0 100%;
	}

	label {
		color: $c-violet;
		display: inline-block;
		font-size: em(20);
		padding-bottom: .5rem;
	}
}

.form-input[type=text],
.form-input[type=email],
textarea.form-input {
	border: 0;
	outline: 0;
	padding: 1rem;
	background-color: $c-input;
	font-size: em(20);
	font-family: inherit;
	width: 100%;
	transition: 300ms ease;
	resize: none;
	color: $c-violet;

	&::placeholder {
		color: $c-violet;
		opacity: .95;
	}
}

.form-input[type=submit] {
	background-color: $c-white;
	border: 4px solid $c-violet;
	border-radius: 6px;
	color: $c-violet;
	cursor: pointer;
	display: inline-block;
	font-size: em(18);
	font-weight: $fw-bold;
	padding: 1rem 3rem;
	text-transform: uppercase;

	&:focus {
		outline: 1px solid $c-violet;
		outline-offset: 1px;
	}
}

.form-message {
	background-color: $c-white;
	padding: 2rem;
	position: absolute;
	left: 0;
	top: 0;
	right: 0; bottom: 0;
	opacity: 0;
	text-align: center;
	transition: opacity 450ms;
	z-index: -1;

	span {
		font-size: em(20);

		&::before {
			content: "✓";
			height: 14px;
			width: 14px;
			margin-right: .5rem;
		}

		&.success {
			color: $c-green;
		}

		&.failed {
			color: $c-orange;
		}
	}

	&.active {
		opacity: 1;
		z-index: 2;
	}
}

/* Custom design Select
---------------------*/
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	outline: 0;
	box-shadow: none;
	border: 0!important;
	background: $c-rosepale;
	background-image: none;
	color: $c-violet;
	cursor: pointer;
	font-family: inherit;
	font-size: 1.25rem;
	flex: 1;
	padding: 0 .5em;
	width: 100%;
}

select::-ms-expand {
	display: none;
}

.select {
	position: relative;
	display: flex;
	max-width: 100%;
	height: 3em;
	line-height: 3;
	background: #5d2c7b;
	overflow: hidden;
	border-radius: 0;

	select {
		&:focus {
			outline: 1px solid $c-violet;
			outline-offset: -1px;
		}
	}

	&.m--violet {
		select {
			background: $c-violet;
			color: $c-white;

			&:focus {
				outline-color: #fff;
			}
		}

		&::after {
			background: $c-violet;
			color: $c-white;
		}
	}
}

.select::after {
	content: '\25BC';
	background: $c-rosepale;
	color: $c-violet;
	position: absolute;
	top: 1px;
	right: 1px;
	bottom: 1px;
	padding: 0 1em;
	cursor:pointer;
	pointer-events:none;
	transition:.25s all ease;
}

.select:hover::after {
	//color: #23b499;
}
/* End custom select
---------------------*/


/* Some adjusts
---------------------*/
.form-contact {

	.select {
		&::after {
			margin-right: 1rem;
		}
	}

	select {
		font-size: em(16, 18);
		padding: 1em;
	}

	textarea.form-input {
		min-height: 180px;
	}
}

@media screen and (min-width:$bp-s) {
	.form-field {

		/* Search header
		----------------*/
		&.m--search {
			width: 200px;
		}
	}
}

@media screen and (min-width:$bp-m) {

	.form-field {
		flex: 0 0 100%;
		position: relative;
		padding-bottom: 2rem;

		&.m--right {
			text-align: right;
		}

		&.m--1-3 {
			flex: 0 0 33%;
		}

		&.m--2-3 {
			flex: 0 0 66%;
		}
	}

	.form-input[type=submit] {
		transition: background-color 250ms, color 250ms;

		&:hover {
			background-color: $c-violet;
			color: $c-white;
		}
	}
}

@media screen and (min-width:860px) {
	.form-field {

		/* Search header
		----------------*/
		&.m--search {
			width: 300px;
		}
	}
}

@media screen and (min-width:$bp-l) {
	.select {
		font-size: em(22);
	}

	.dropdown .select {
		min-width: 16rem;
		max-width: 16rem;
	}
}
