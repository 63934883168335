/**
 * ==================== @ CTA
 */
 
.cta-wrapper-desktop {
	max-width: 82em;
	margin: 0 auto;
	width: 100%;
}

/* Accessyway
--------------------*/
.cta-accessibility {
	padding-right: 0.5rem;
	position: fixed;
	bottom: 2.5rem;
	right: calc(50vw - 40em);
	z-index: 20;
  
  .breizh-acsb-trigger {
    //width: 45px;
    height: 45px;
    border-radius: 99px;
    min-width: 45px;
    padding: 0;
    border: none;
    background-color: $c-violet;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
      color: #fff;
      display: inline-block;
      font-weight: 700;
      padding: 0;
      text-transform:  uppercase;
      overflow: hidden;
      width: 0;
      transition: width 350ms, padding 360ms;
    }

    &:hover {
      span {
        padding: 8px 0 8px 16px;
        width: 118px;
      }
    }
  }
  
  .acsb-trigger-svg {
    height: 30px;
    width: 24px;
    margin: 0 10px;
    fill: rgb(255, 255, 255) !important;
  }
}