.entry {
}

.section-seo {
	.entry, p, a, h1, h2, h3, h4, h5 {
		color: $c-white;
	}
}

h1, h2, h3, h4, h5 {
	font-family: $font-alt;
	line-height: 1.1;
	margin: .5rem 0 1rem 0;
}

h1 {
	font-size: em(30);
	font-weight: $fw-semibold;
	margin-bottom: 2rem;
		
	&.m--404 {
		font-size: em(44);
	}
}

h2 {
	color: $c-green; /* default color */
	font-size: em(25);
	font-weight: $fw-semibold;
	line-height: 1.2;
	position: relative;
}

.layout-img-txt,
.layout-txt-img {
	h2:not(.notaligned) {
		margin-top: 0;
	}
}

h3 {
	color: $c-green; /* default color */
	font-size: em(21);
	font-weight: $fw-bold;
	margin: .5rem 0;
}

h4 {
  color: $c-green; /* default color */
	font-size: em(16);
	font-weight: $fw-bold;
	margin: .25rem 0;
}

p {
	font-size: em(14);
	line-height: 1.3;
	margin: .5rem 0 1rem 0;
	
	a {
		color: inherit;
		position: relative;
		word-break: break-all;
		text-decoration-line: underline;
		text-decoration-color: $c-green;
  	text-underline-offset: 2px;
		text-decoration-skip-ink: auto;
		text-decoration-thickness: 1.4px;
		transition: text-decoration-color 200ms;
		
		&:hover {
			text-decoration-color: $c-green-txt;
		}
	}
}
	
@media all and (min-width : $bp-m) {

	h1 {
		font-size: em(45);
		
		&.m--404 {
			font-size: em(80);
		}
	}

	h2 {
		font-size: em(40);	
	}

	h3 {
		font-size: em(28);
	}

	h4 {
		font-size: em(20);
	}
	
	p {
		font-size: em(20);
	}
}