/**
 * ==================== @ SASS PLACEHOLDERS
 */

%icon-font {
  font-family: $icons-fontname;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%clearfix {
  &::before, &::after { content: " "; display: table; }
  &::after { clear: both; }
}

%ghost-valign {
  white-space: nowrap;

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

%ghost-valign-inner {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
