/**
 * ==================== @ ARTICLES & TEASER
 */

.news-slides {	
}

.news-teaser {
	padding: 0 !important;
	
	figure {
		border: 4px solid $c-green;
		position: relative;
		
		figcaption {
			background-color: $c-green;
			border-radius: 0 10px 10px 0;
			color: $c-white;
			font-size: em(18);
			padding: .5rem 1rem;
			position: absolute;
			left: 0;
			bottom: 1rem;	
			z-index: 2;		
		}
		
		img {
			transition: transform 250ms;
		}
	}
	
	.news-teaser__caption {
		padding-top: 1rem;
	}
	
	time {
		font-size: em(18);
	}
	
	h3 {
		font-size: em(21);
	}
	
	p {
		font-size: em(20);
	}
	
	h3 {
		color: $c-green;
		font-family: $font-stack;
	}
}


.articles {
	
	
	/* Related
	----------------*/
	.news-related {
		padding: 2rem;
	}
	
}

.article {
	
	.page-header__metas {
		display: flex;
		align-items: center;
	}
	
	.page-header__tag {
		background-color: $c-rose;
		border-radius: 24px;
		color: $c-violet;
		font-size: em(18);
		font-weight: $fw-bold;
		margin-right: 1rem;
		padding: .75rem 1.5rem;
	}	
	
	.page-header__date {
		font-size: em(20);		
	}
}


@media all and (min-width : $bp-m) {
	.news-teaser {
		
		time {
			font-size: em(18);
		}
		
		h4 {
			font-size: em(22);
		}
		
		p {
			font-size: em(21);
		}
		
		h3 {
			color: $c-green;
			font-family: $font-stack;
		}
		
		figure:hover {
			
			img {
				transform: scale(1.02,1.02);
			}			
		}
	}

	.article {
	}
}