
.page-map-filter__wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	max-width: 80em;
	margin: 2rem auto 0 auto;
	padding-left: 1rem;
	padding-right: 1rem;
	
	div {
		flex: 0 0 100%;
	}
	
	.label, .result {
		color: $c-violet;
	}
	
	.label {
		font-size: em(30);
	}
	
	.result {
		font-size: em(18);
	}
}

.page-map {
	margin: 0;
	padding: 0;
	
	.grid-wrapper {
		padding-top: 0;
	}
	
	.blocks-list {
		margin-top: 2rem;
		opacity: 1;
		transition: opacity 350ms;
		
		&.hide {
			opacity: 0;
		}
	}
	
	.list-wrapper {
		min-height: 50vh;
		
		.list-scroll {
			height: calc(100vh - 220px);
			overflow-x: hidden;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			position: relative;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	
	.map-wrapper {
		background-color: $c-grey;
		border-bottom-left-radius: $radius-60;
		
		.js-map-view {
			min-height: 40vh;
		}
	}
}

/* === 1024px === */
@media all and (min-width : $bp-l) {
	
	.page-map-filter__outer {
		background-color: $c-violet;		
	}
	
	.page-map-filter__wrapper {
		flex-wrap: nowrap;
		
		div {
			flex: 0 0 auto;
		}
		
		.label {			
			padding-right: 1.5rem;
		}
	
		.label, .result {
			color: $c-white;
		}
	
		.dropdown {			
			border-right: 1px solid $c-white;
			&.m--first {
				border-left: 1px solid $c-white;
			}
		}
		
		.result {
			flex: 1 1 auto;
			text-align: right;
			
			.result-edge {
				transform: translateX(calc((50vw - 640px) - 1rem));
			}			
		}
	}
	
	.page-map {
		margin-bottom: 4rem;
	
		.list-wrapper {
		}
		
		.map-wrapper {
		
			.js-map-view {
				height: calc(100vh - 220px);
			}
		}		
	}
}