/**
 * ==================== @ AGENDA & EVENEMENTS
 */

.events-slides {

	.section-title h2 {
		color: $c-violet;
	}

	.grid-item {
		padding: 0;
	}

	.event-slide__caption {
		background-color: $c-rose;
		border-radius: $radius-24 $radius-24 0 0;
		color: $c-violet;
		min-height: 200px;
		padding: 2rem 1rem 3rem 1rem;
		position: relative;
		text-align: center;
		transition: background-color 250ms;

		h3 {
			color: $c-violet;
		}

		span {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 1rem;
			text-align: center;
		}
	}

	.event-type {
		background-color: $c-violet;
		border-radius: 0 0 $radius-24 $radius-24;
		color: $c-white;
		font-size: em(20);
		padding: 1rem 0;
		text-align: center;

		.svg-icon {
			margin-right: .25rem;
			vertical-align: -2px;
		}
	}
}

.section-event .grid-item {

	h2, h3 {
		color: $c-green;
	}

	a, p {
		color: $c-green-txt;
	}

	a {
		font-weight: $fw-bold;
	}
}

/* Page Event
----------------*/
.event {

	.page-header__metas {
		display: flex;
		align-items: center;
	}

	.page-header__tag {
		background-color: $c-rose;
		border-radius: 24px;
		color: $c-violet;
		font-size: em(18);
		font-weight: $fw-bold;
		margin-right: 1rem;
		padding: .75rem 1.5rem;
	}

	.page-header__infos {
		font-size: em(20);

		span {
			display: inline-block;
			margin-right: 1rem;
		}

		.header-event-author {

			&::before {
				content: '\2022';
				font-size: em(32);
				line-height: 0;
				margin-right: 8px;
				vertical-align: -8px;
			}
		}
	}
}

@media all and (min-width : $bp-m) {

	.events-slides {

		.event-slide__caption:hover {
			background-color: #ded5e4;
		}

		.event-slide__caption {
			height: 250px;
			padding: 3rem 2rem;
		}
	}
}
